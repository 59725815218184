import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const Map = ({ latitude, longitude, className }) => {
  const mapContainerStyle = {
    width: "100%",
    height: "400px",
    borderRadius: "5px",
  };

  const mapOptions = {
    zoom: 15,
    center: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU",
  });

  return isLoaded ? (
    <GoogleMap mapContainerStyle={className} options={mapOptions}>
      <Marker
        position={{
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        }}
      />
    </GoogleMap>
  ) : null;
};

export default Map;
