import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, matchPath, useLocation } from "react-router-dom";
import { BiCategory } from "react-icons/bi";
import { AccountHeader, Breadcrum, MobileAccountHeader } from "../Component";

const menu = [
  {
    id: 1,
    name: "Dashboard",
    link: "/me",
  },

  {
    id: 2,
    name: "Profile Setting",
    link: "/me/profile-setting",
  },

  {
    id: 3,
    name: "Coupon",
    link: "/me/coupon-offers",
  },

  {
    id: 4,
    name: "My Ads",
    link: "/me/my-ads",
  },

  {
    id: 5,
    name: "Offer/Messages",
    link: "/me/offers",
  },

  {
    id: 6,
    name: "Deals",
    link: "/me/deals",
  },

  {
    id: 7,
    name: "Payments",
    link: "/me/payments",
  },

  {
    id: 13,
    name: "My Inspections",
    link: "/me/inspections",
  },

  {
    id: 8,
    name: "My Favourites",
    link: "/me/favourites",
  },

  {
    id: 9,
    name: "Privacy Setting",
    link: "/me/privacy-setting",
  },

  {
    id: 10,
    name: "Referral Management",
    link: "/me/referral",
  },

  {
    id: 11,
    name: "Agent Request",
    link: "/me/agent-request",
  },

  {
    id: 12,
    name: "Logout",
    link: "/me/logout",
  },
];

const AccountRoute = ({ Component }) => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const { pathname } = useLocation();
  const pathroad = matchPath("/me", pathname);
  const token = Cookies.get("token");

  useEffect(() => {
    localStorage.removeItem("search-data");
  }, []);

  if (!token) {
    return <Navigate to={`/auth/sign-in`} replace />;
  }

  const abc = menu.find((val) => val.link === pathname);

  return (
    <div className="bg-gray-50">
      <div className="w-11/12 mx-auto xl:w-3/4 py-16 md:py-24 relative ">
        <div className="md:hidden mt-7 md:mt-10 flex items-center justify-between">
          <h4 className="font-medium text-2xl md:text-3xl text-textPrimary">
            My Account
          </h4>
          <BiCategory
            className="block md:hidden text-3xl text-textSecondary"
            onClick={() => setMobileMenu(!mobileMenu)}
          />
        </div>
        <hr className="block md:hidden w-full mt-5 md:mt-7" />

        <div className="mt-10">
          <Breadcrum
            title="Home"
            subTitle="My Account"
            subTitle2={abc?.name ? abc?.name : "My Ads"}
          />
        </div>

        <div className="grid grid-cols-12 mt-5 md:mt-7 gap-x-5">
          <div className="hidden md:block md:col-span-4 lg:col-span-3 w-full h-full">
            <AccountHeader />
          </div>
          <div className="col-span-12 md:col-start-5 lg:col-start-4 md:col-end-13 w-full h-full">
            {pathroad ? <Component /> : <Outlet />}
          </div>
        </div>

        <MobileAccountHeader
          mobileMenu={mobileMenu}
          setMobileMenu={setMobileMenu}
        />
      </div>

      {mobileMenu && (
        <div
          className="w-full h-full absolute top-0  bg-black opacity-10"
          onClick={() => setMobileMenu(false)}
        ></div>
      )}
    </div>
  );
};
export default AccountRoute;
