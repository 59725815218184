import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  message: "",
  errMsg: "",
  logo: "",
};

export const Logo = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPLOAD_LOGO_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
        logo: action.payload.source.src,
      };

    case ActionTypes.UPLOAD_LOGO_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.REMOVE_LOGO_DATA:
      return {
        isloading: true,
        success: false,
        message: "",
        errMsg: "",
        logo: "",
      };

    default:
      return state;
  }
};
