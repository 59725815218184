import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  aboutus: "",
  addedAt: "",
};

export const Aboutus = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ABOUTUS:
      return {
        ...state,
        isloading: false,
        success: true,
        aboutus: action.payload.article_details,
      };

    case ActionTypes.REMOVE_ABOUTUS:
      return {
        isloading: true,
        success: false,
        aboutus: "",
        addedAt: "",
      };

    default:
      return state;
  }
};
