export const MY_ADS_LOADING = "MY_ADS_LOADING";
export const GET_TOTAL_VIEWS = "GET_TOTAL_VIEWS";
export const GET_MY_ADS = "GET_MY_ADS";
export const GET_ADS_FORM = "GET_ADS_FORM";
export const POST_ADS_SUCCESS = "POST_ADS_SUCCESS";
export const POST_ADS_FAIL = "POST_ADS_FAIL";
export const GET_EDIT_FORM = "GET_EDIT_FORM";
export const EDIT_ADS_SUCCESS = "EDIT_ADS_SUCCESS";
export const EDIT_ADS_FAIL = "EDIT_ADS_FAIL";
export const GET_MY_INSPECTION = "GET_MY_INSPECTION";
export const GET_PAYMENT_TYPES = "GET_PAYMENT_TYPES";
export const GET_DELETE_REASON = "GET_DELETE_REASON";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAIL = "DELETE_POST_FAIL";
export const REMOVE_MY_ADS_DATA = "REMOVE_MY_ADS_DATA";
