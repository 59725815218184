import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const loading = () => ({
  type: ActionTypes.INBOX_LOADING,
});

export const removeInboxData = () => ({
  type: ActionTypes.REMOVE_INBOX_DATA,
});

export const fetchGetAllOfferMessages =
  (sent, page, token) => async (dispatch) => {
    await axios
      .get(`${BaseUrl}/offermessages_new?page=${page}&status=${sent}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_OFFER_MESSAGE,
          payload: data,
        });
      });
  };

export const fetchGetOfferListInPost = (postId, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/offerlists?post_id=${postId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_OFFER_LIST_IN_POST,
        payload: data,
      });
    });
};

export const fetchGetChatList =
  (postId, offerId, token) => async (dispatch) => {
    await axios
      .get(`${BaseUrl}/offers_detail?post_id=${postId}&offer_id=${offerId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_CHAT_LIST,
          payload: data,
        });
      });
  };

export const fetchSendMessage = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/add_comment`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SEND_MESSAGE_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.SEND_MESSAGE_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchSendFile = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/upload_comment_file`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SEND_FILE_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.SEND_FILE_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchGetAgentEnquiry = (page, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/agent_enquiries?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_AGENT_ENQUIRY,
        payload: data,
      });
    });
};

export const fetchGetAgentEnquiryDetail =
  (enquiryId, userId, token) => async (dispatch) => {
    await axios
      .get(`${BaseUrl}/agent_enquiry_users/${enquiryId}/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_AGENT_ENQUIRY_DETAIL,
          payload: data,
        });
      });
  };

export const fetchGetAgentEnquiryChat =
  (enquiryId, userEnquiryId, token) => async (dispatch) => {
    await axios
      .get(`${BaseUrl}/agent_enquiry_users/${enquiryId}/${userEnquiryId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_AGENT_ENQUIRY_CHAT,
          payload: data,
        });
      });
  };

export const fetchSendEnquiryReply = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/add_enquiryChatMsg`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SEND_AGENT_ENQUIRY_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.SEND_AGENT_ENQUIRY_FAIL,
        payload: e.response.data,
      });
    });
};
