import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  message: "",
  otpMsg: "",
  resendMsg: "",
  forgotMsg: "",
  changeMsg: "",
  otpErr: "",
  errMsg: "",
  token: "",
};

export const Auth = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SOCIAL_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: "Login Successful",
      };

    case ActionTypes.SOCIAL_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: "Login Successful",
        token: action.payload.access_token,
      };

    case ActionTypes.LOGIN_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message:
          "Please check your email and follow the instruction to activate your account.",
      };

    case ActionTypes.SIGNUP_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.OTP_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        otpMsg: action.payload.message,
      };

    case ActionTypes.OTP_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        otpErr: action.payload.message,
      };

    case ActionTypes.RESEND_OTP_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        resendMsg: action.payload.message,
      };

    case ActionTypes.RESEND_OTP_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        forgotMsg: action.payload.message,
      };

    case ActionTypes.FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        changeMsg: action.payload.message,
      };

    case ActionTypes.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.REMOVE_AUTH_DATA:
      return {
        isloading: true,
        success: false,
        message: "",
        otpMsg: "",
        resendMsg: "",
        forgotMsg: "",
        changeMsg: "",
        errMsg: "",
        otpErr: "",
        token: "",
      };

    default:
      return state;
  }
};
