import { animateScroll } from "react-scroll";

export const scrollToTop = () => {
  animateScroll.scrollToTop({
    duration: 2000,
    smooth: "easeInOutQuint",
  });
};

export const scrollToBottom = () => {
  animateScroll.scrollToBottom({
    duration: 2000,
    smooth: "easeInOutQuint",
  });
};
