import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const carsLoading = () => ({
  type: ActionTypes.CARS_LOADING,
});

export const removeCarData = () => ({
  type: ActionTypes.REMOVE_CAR_DATA,
});

export const fetchGetCarBodyTypes = () => async (dispatch) => {
  await axios.get(`${BaseUrl}/body_types`).then(({ data }) => {
    dispatch({
      type: ActionTypes.GET_BODY_TYPES,
      payload: data,
    });
  });
};

export const fetchGetBikeType = () => async (dispatch) => {
  await axios.get(`${BaseUrl}/bike_types`).then(({ data }) => {
    dispatch({
      type: ActionTypes.GET_BIKE_TYPE,
      payload: data,
    });
  });
};

export const fetchGetBrands = (id) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/makes?cat_id=${id}&is_popular=1`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_BRANDS,
        payload: data,
      });
    });
};

export const fetchGetModelByMake = (makeId, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/models?make_id=${makeId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_MODEL_BY_MAKE,
        payload: data,
      });
    });
};
