import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const postImageLoading = () => ({
  type: ActionTypes.POST_IMAGE_LOADING,
});

export const removePostImageData = () => ({
  type: ActionTypes.REMOVE_POST_IMAGE_DATA,
});

export const fetchUploadPostImage = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/upload_post_image`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.UPLOAD_POST_IMAGE_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.UPLOAD_POST_IMAGE_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchDeletePostImage = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/remove_image`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.DELETE_IMAGE_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.DELETE_IMAGE_FAIL,
        payload: e?.response?.data,
      });
    });
};
