import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const categoryLoading = () => ({
  type: ActionTypes.CATEGORY_LOADING,
});

export const removeCategoryData = () => ({
  type: ActionTypes.REMOVE_CATEGORY_DATA,
});

export const fetchGetPopularCategories = () => async (dispatch) => {
  await axios.get(`${BaseUrl}/presets/popular_category`).then(({ data }) => {
    dispatch({
      type: ActionTypes.GET_POPULAR_CATEGORY,
      payload: data,
    });
  });
};

export const fetchGetAllCategories = () => async (dispatch) => {
  await axios.get(`${BaseUrl}/search_categories`).then(({ data }) => {
    dispatch({
      type: ActionTypes.GET_ALL_CATEGORIES,
      payload: data,
    });
  });
};

export const fetchGetSecondLevelCategories = (parentId) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/post_categories?parent_id=${parentId}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_SECOND_LEVEL_CATEGORIES,
        payload: data,
      });
    });
};

export const fetchGetThirdLevelCategories = (id) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/post_categories?parent_id=${id}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_THIRD_LEVEL_CATEGORIES,
        payload: data,
      });
    });
};

export const fetchGetFourthLevelCategories = (id) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/post_categories?parent_id=${id}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_FOURTH_LEVEL_CATEGORIES,
        payload: data,
      });
    });
};
