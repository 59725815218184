export const INBOX_LOADING = "INBOX_LOADING";
export const GET_OFFER_MESSAGE = "GET_OFER_MESSAGE";
export const GET_OFFER_LIST_IN_POST = "GET_OFFER_LIST_IN_POST";
export const GET_CHAT_LIST = "GET_CHAT_LIST";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAIL = "SEND_MESSAGE_FAIL";
export const SEND_FILE_SUCCESS = "SEND_FILE_SUCCESS";
export const SEND_FILE_FAIL = "SEND_FILE_FAIL";

export const GET_AGENT_ENQUIRY = "GET_AGENT_ENQUIRY";
export const GET_AGENT_ENQUIRY_DETAIL = "GET_AGENT_ENQUIRY_DETAIL";
export const GET_AGENT_ENQUIRY_CHAT = "GET_AGENT_ENQUIRY_CHAT";
export const SEND_AGENT_ENQUIRY_SUCCESS = "SEND_AGENT_ENQUIRY_SUCCESS";
export const SEND_AGENT_ENQUIRY_FAIL = "SEND_AGENT_ENQUIRY_FAIL";

export const REMOVE_INBOX_DATA = "REMOVE_INBOX_DATA";
