import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const referralLoading = () => ({
  type: ActionTypes.REFERRAL_LOADING,
});

export const removeReferralData = () => ({
  type: ActionTypes.REMOVE_REFERRAL_DATA,
});

export const fetchGetInviteLInks = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/invite_link`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_INVITE_LINKS,
        payload: data,
      });
    });
};

export const fetchGetReferralLogin = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/referal_login_friends`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_REFERRAL_LOGIN,
        payload: data,
      });
    });
};
