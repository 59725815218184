import React from "react";
import { useEffect } from "react";
import { FaMoneyBills } from "react-icons/fa6";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { scrollToTop } from "../../Component/NavigateTop";
import Cookies from "js-cookie";
import {
  fetchGetPaymentHistory,
  removePaymentData,
} from "../../Redux/Payments/action";
import {
  Loading,
  Pagination,
  PaymentCard,
  SinglePagnination,
} from "../../Component";
import { useState } from "react";

const PaymentHistory = () => {
  const [paymentMethod, setPaymentMethod] = useState("");
  const dispatch = useDispatch();
  const token = Cookies.get("token");
  let params = new URLSearchParams(document.location.search);
  let pg = params.get("page");
  const [page, setPage] = useState(pg ? Number(pg) : 1);

  const loading = useSelector((state) => state.payment.isloading, shallowEqual);
  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  const payments = useSelector((state) => state.payment.payment, shallowEqual);
  const totalData = useSelector(
    (state) => state.payment.totalData,
    shallowEqual
  );

  const totalPage = Math.ceil(totalData / 10);

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetPaymentHistory(page, paymentMethod, token));
    return () => {
      dispatch(removePaymentData());
    };
  }, [page, paymentMethod]);

  return (
    <div className="w-full h-full mt-2 relative">
      <div className="w-full flex items-center gap-x-3">
        <h6 className="text-2xl font-semibold">Payments</h6>
        <div className="w-fit py-1 px-3 flex items-center gap-x-3 border rounded-full bg-white">
          <FaMoneyBills className="text-xl text-primary" />
          <p className="font-semibold">{profile.classi_credit} Coins</p>
        </div>
      </div>

      <div className="w-full mt-10 border rounded-xl bg-white p-3">
        <p className="text-sm">Payment Method</p>

        <select
          className="w-fit border bg-white rounded-xl outline-none py-1.5 mt-3 text-sm text-gray-700 px-3"
          onChange={(e) => setPaymentMethod(e.target.value)}
        >
          <option className="text-gray-700 text-sm py-3" value="">
            All
          </option>
          <option className="text-gray-700 text-sm py-3" value="Classi Credit">
            Classi Credit
          </option>
          <option className="text-gray-700 text-sm py-3" value="Paypal">
            Paypal
          </option>
          <option className="text-gray-700 text-sm py-3" value="Stripe">
            Stripe
          </option>
        </select>
      </div>

      {loading ? (
        <div className="w-full">
          <Loading />{" "}
        </div>
      ) : payments && payments.length ? (
        <>
          {payments.map((val) => {
            return <PaymentCard val={val} key={val.payment_id} />;
          })}

          <div className="mt-10">
            {totalPage > 5 ? (
              <Pagination
                setPage={setPage}
                page={page}
                totalData={totalData}
                totalPage={totalPage}
              />
            ) : (
              <SinglePagnination
                setPage={setPage}
                page={page}
                totalData={totalData}
                totalPage={totalPage}
              />
            )}
          </div>
        </>
      ) : (
        <h1 className="text-center mt-5 text-2xl font-semibold">
          Sorry!! No History Avaiable
        </h1>
      )}
    </div>
  );
};

export default PaymentHistory;
