import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  news: [],
  newsDetail: "",
};

export const News = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_NEWS_DATA:
      return {
        ...state,
        isloading: false,
        success: true,
        news: action.payload,
      };

    case ActionTypes.GET_NEWS_BY_ID:
      return {
        ...state,
        isloading: false,
        success: true,
        newsDetail: action.payload,
      };

    case ActionTypes.REMOVE_NEWS_DATA:
      return {
        isloading: true,
        success: false,
        news: [],
        newsDetail: "",
      };

    default:
      return state;
  }
};
