import React, { useEffect, useRef, useState } from "react";
import Button from "../Button/Button";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchOTPVerify, removeAuthData } from "../../Redux/Auth/action";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const OTP = () => {
  const [otp, setOtp] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
  });
  const { otp1, otp2, otp3, otp4 } = otp;
  const errMsg = useSelector((state) => state.auth.otpErr, shallowEqual);
  const otpMsg = useSelector((state) => state.auth.otpMsg, shallowEqual);

  const input1 = useRef();
  const input2 = useRef();
  const input3 = useRef();
  const input4 = useRef();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const email = localStorage.getItem("email");

  const handleOtpChange = (e, inputRef) => {
    const inputOtp = e.target.value.split("");
    if (e.target.value.length === 1 && inputRef?.current) {
      inputRef.current.focus();
    }
    if (inputOtp.length > 1 && inputOtp.length <= 4) {
      inputOtp.map((num, index) => {
        setOtp((prev) => {
          return { ...prev, [`otp${index + 1}`]: num };
        });
      });
    } else {
      setOtp((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }
  };

  const handleSubmitOtp = () => {
    const body = {
      email: email,
      code: otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4,
    };

    dispatch(fetchOTPVerify(body));
  };

  useEffect(() => {
    otpMsg && toast.success(otpMsg);
    otpMsg && navigate("/auth/sign-in");
    otpMsg && otpMsg.length && dispatch(removeAuthData());
  }, [otpMsg]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    errMsg && errMsg.length && dispatch(removeAuthData());
  }, [errMsg]);

  return (
    <>
      <div className="otp--input--field flex flex-row justify-center gap-4 m-8">
        <input
          maxLength={1}
          onChange={(e) => handleOtpChange(e, input2)}
          name="otp1"
          value={otp1}
          className="border w-14 h-14 rounded-lg outline-primary text-center"
          style={{ background: "#F7F9FC" }}
          type="number"
          required
          ref={input1}
        />
        <input
          maxLength={1}
          name="otp2"
          value={otp2}
          onChange={(e) => handleOtpChange(e, input3)}
          className="border w-14 h-14 rounded-lg outline-primary text-center"
          style={{ background: "#F7F9FC" }}
          type="number"
          required
          ref={input2}
        />
        <input
          maxLength={1}
          name="otp3"
          value={otp3}
          onChange={(e) => handleOtpChange(e, input4)}
          className="border w-14 h-14 rounded-lg outline-primary text-center"
          style={{ background: "#F7F9FC" }}
          type="number"
          required
          ref={input3}
        />
        <input
          maxLength={1}
          name="otp4"
          value={otp4}
          onChange={(e) => handleOtpChange(e)}
          className="border w-14 h-14 rounded-lg outline-primary text-center"
          style={{ background: "#F7F9FC" }}
          type="number"
          required
          ref={input4}
        />
      </div>
      <Button
        value="Submit"
        className="bg-primary py-3 w-full text-center text-white rounded-xl"
        handleClick={handleSubmitOtp}
      />
    </>
  );
};

export default OTP;
