import React from "react";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetPolicies,
  removeTermsPrivacy,
} from "../../Redux/Terms-Privacy/action";
import { scrollToTop } from "../../Component/NavigateTop";
import { Loading } from "../../Component";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();

  const loading = useSelector(
    (state) => state.termsprivacy.isloading,
    shallowEqual
  );
  const privacyPolicy = useSelector(
    (state) => state.termsprivacy.policy,
    shallowEqual
  );

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetPolicies(10));
    return () => {
      dispatch(removeTermsPrivacy());
    };
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="py-16 md:py-24 w-11/12 xl:w-3/4 mx-auto">
      <p
        className="mt-10 text-textPrimary"
        dangerouslySetInnerHTML={{
          __html: privacyPolicy,
        }}
      ></p>
    </div>
  );
};

export default PrivacyPolicy;
