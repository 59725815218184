import React, { useEffect } from "react";
import Avatar from "react-avatar";
import { FaMoneyBills } from "react-icons/fa6";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { PiHandWavingFill } from "react-icons/pi";
import { IoMdAddCircleOutline } from "react-icons/io";
import Cookies from "js-cookie";
import {
  fetchGetDashboardData,
  fetchGetMyActivity,
  fetchGetRecentlyApprovedAds,
  fetchGetTodo,
  removeDashboardData,
} from "../../Redux/Dashboard/action";
import { Loading } from "../../Component";
import DashboardData from "./DashboardData";
import ApprovedAds from "./ApprovedAds";
import Todo from "./Todo";
import { toast } from "react-toastify";
import MyActivity from "./MyActivity";
import TotalViews from "./TotalViews";
import { fetchGetTotalViews } from "../../Redux/MyAds/action";
import { scrollToTop } from "../../Component/NavigateTop";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const navigate = useNavigate();

  const profile = useSelector((state) => state.profile.profile, shallowEqual);

  const loading = useSelector(
    (state) => state.dashboard.isloading,
    shallowEqual
  );

  const adsLoading = useSelector(
    (state) => state.myAds.isloading,
    shallowEqual
  );

  const message = useSelector((state) => state.dashboard.message, shallowEqual);
  const errMsg = useSelector((state) => state.dashboard.errMsg, shallowEqual);

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetTotalViews(token));
    dispatch(fetchGetDashboardData(token));
    dispatch(fetchGetRecentlyApprovedAds(token));
    dispatch(fetchGetMyActivity(token));
    dispatch(fetchGetTodo(token));

    return () => {
      dispatch(removeDashboardData());
    };
  }, []);

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeDashboardData());
    setTimeout(() => {
      message && dispatch(fetchGetTotalViews(token));
      message && dispatch(fetchGetDashboardData(token));
      message && dispatch(fetchGetRecentlyApprovedAds(token));
      message && dispatch(fetchGetMyActivity(token));
      message && dispatch(fetchGetTodo(token));
    }, 100);
  }, [message]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    errMsg && dispatch(removeDashboardData());
    setTimeout(() => {
      errMsg && dispatch(fetchGetTotalViews(token));
      errMsg && dispatch(fetchGetDashboardData(token));
      errMsg && dispatch(fetchGetRecentlyApprovedAds(token));
      errMsg && dispatch(fetchGetMyActivity(token));
      errMsg && dispatch(fetchGetTodo(token));
    }, 100);
  }, [errMsg]);

  if (loading || adsLoading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-full h-full mt-2 relative">
      <div className="w-full flex items-center gap-x-3">
        <h6 className="text-2xl font-semibold">Dashboard</h6>
        <div className="w-fit py-1 px-3 flex items-center gap-x-3 border rounded-full bg-white">
          <FaMoneyBills className="text-xl text-primary" />
          <p className="font-semibold">{profile.classi_credit} Coins</p>
        </div>
      </div>

      <div className="w-full mt-7 flex items-center justify-between flex-wrap lg:flex-nowrap">
        <div className="flex gap-x-5">
          {profile && profile.image ? (
            <div className="w-16 h-16">
              <img
                src={profile.image}
                alt=""
                className="w-full h-full rounded-full"
              />{" "}
            </div>
          ) : (
            <Avatar
              name={profile && profile.first_name}
              size={65}
              round={true}
              color="#2E8B4A"
            />
          )}
          <div>
            <div className="flex items-center gap-x-2">
              <p className="font-semibold text-lg">
                Hello, {profile.first_name} {profile.last_name}
              </p>
              <PiHandWavingFill className="text-2xl text-yellow-400" />
            </div>
            <p className="text-sm mt-2">
              Here is what is happening with your store today
            </p>
          </div>
        </div>

        <div
          className={`bg-primary mt-5 lg:mt-0 border flex items-center gap-x-3 py-3 cursor-pointer justify-center w-fit text-sm px-7 rounded-xl text-white`}
          onClick={() => navigate(`/me/post-ads`)}
        >
          <IoMdAddCircleOutline className="text-xl" />
          <p>Post an Ad</p>
        </div>
      </div>

      <hr className="w-full mt-5" />

      {/* dashboard data */}
      <DashboardData />

      <div className="w-full grid grid-cols-12 mt-10 gap-x-5">
        <div className="col-span-12 lg:col-span-6">
          <TotalViews />
        </div>
        <div className="col-start-1 lg:col-start-7 col-end-13 mt-10 lg:mt-0">
          <MyActivity />
        </div>
      </div>

      <div className="w-full grid grid-cols-12 gap-x-5 mt-10">
        <div className="col-span-12 lg:col-span-6">
          <ApprovedAds />
        </div>
        <div className="col-start-1 lg:col-start-7 col-end-13 mt-10 lg:mt-0">
          <Todo />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
