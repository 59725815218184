import React from "react";
import { Button, FormikControl } from "../../Component";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { fetchUpdatePassword } from "../../Redux/Profile/action";
import Cookies from "js-cookie";

const Password = () => {
  const [showCurrent, setSetCurrent] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const initialValues = {
    current_password: "",
    password: "",
    password_confirm: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password is too short")
      .required("Required*"),
    password_confirm: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    dispatch(fetchUpdatePassword(values, token));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 5000);
  };

  return (
    <div className="w-full border bg-white rounded-xl mt-10">
      <p className="font-medium p-3">Change Password</p>
      <hr className="w-full" />
      <div className="p-3 mt-3">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <div className="w-full">
                <FormikControl
                  label="Current Password"
                  control="password"
                  name="current_password"
                  placeholder="Enter Your Old Password"
                  type={`${showCurrent ? "text" : "password"}`}
                  setShowPassword={setSetCurrent}
                  showPassword={showCurrent}
                />
              </div>

              <div className="w-full mt-7">
                <FormikControl
                  label="New Password"
                  control="password"
                  name="password"
                  placeholder="Enter Your New Password"
                  type={`${showNew ? "text" : "password"}`}
                  setShowPassword={setShowNew}
                  showPassword={showNew}
                />
              </div>

              <div className="w-full mt-7">
                <FormikControl
                  label="Confirm Password"
                  control="password"
                  name="password_confirm"
                  placeholder="Confirm Your Password"
                  type={`${showConfirm ? "text" : "password"}`}
                  setShowPassword={setShowConfirm}
                  showPassword={showConfirm}
                  autocomplete="off"
                />
              </div>

              <Button
                value="Change Password"
                type="submit"
                className="border w-full text-sm rounded-2xl mt-8 bg-primary cursor-pointer text-white font-medium py-4 text-center"
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Password;
