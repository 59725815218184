import React from "react";
import { ErrorMessage, Field } from "formik";
import TextError from "../Formik/Error";

const Checkbox = ({ label, name, options, ...rest }) => {
  return (
    <div className="text-sm">
      <label
        htmlFor={name}
        className="pb-2 font-medium flex text-textPrimary text-sm items-center justify-between"
      >
        {label} <ErrorMessage name={name} component={TextError} />
      </label>

      <div className="w-full flex items-center gap-x-6">
        <Field name={name} {...rest}>
          {({ field }) => {
            return options.map((val) => {
              return (
                <div className="flex items-center gap-x-2 mt-3" key={val.id}>
                  <input
                    type="checkbox"
                    id={val.id}
                    {...field}
                    value={val.value}
                    checked={field.value.includes(val.value)}
                  />
                  <label htmlFor={val.value}>{val.key}</label>
                </div>
              );
            });
          }}
        </Field>
      </div>
    </div>
  );
};

export default Checkbox;
