import React from "react";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import { classiBazarLogo } from "../../Assets/Assest";
import Button from "../Button/Button";
import moment from "moment";
import { scrollToTop } from "../NavigateTop";

const AdsCard = ({
  title,
  data,
  handleAddToWishlist,
  handleRemoveWishlist,
  slugs,
  category,
}) => {
  const navigate = useNavigate();

  const location = window.location.pathname;

  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;

    return (
      <div onClick={onClick} className={className}>
        <IoIosArrowBack />
      </div>
    );
  };

  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div onClick={onClick} className={className}>
        <IoIosArrowForward />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 644,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 483,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="w-full">
      <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
        {title}
      </h4>
      <hr className="w-20 border border-primary mt-4" />

      <div className="relative">
        <Slider {...settings}>
          {data &&
            data.map((data) => {
              return (
                <div
                  className="px-1 2xl:px-3 pb-5"
                  key={data.post_id}
                  onClick={() => {
                    navigate(
                      `/ads-detail?i=${data.post_id}&name=${data.title}`
                    );
                  }}
                >
                  <div
                    className={`${
                      location === "/jobs" ? "h-[20rem]" : "min-h-33rem"
                    } mt-10  relative rounded-xl hover:shadow-md border cursor-pointer transition-all duration-300`}
                  >
                    <div
                      className={`${
                        location === "/jobs" ? "h-fit" : "h-52 lg:h-56"
                      } w-full  relative rounded-t-xl overflow-hidden`}
                    >
                      {(data.cat_name === "Jobs" || data.cat_name === "jobs") &&
                      location !== "/jobs" ? (
                        <img
                          src={classiBazarLogo}
                          alt="img"
                          className="w-full h-full object-contain"
                        />
                      ) : location === "/jobs" ? null : data?.images?.[0]
                          ?.thumbnail ? (
                        <img
                          src={data?.images?.[0]?.thumbnail}
                          alt="img"
                          className="w-full h-full object-fill"
                        />
                      ) : (
                        <img
                          src={classiBazarLogo}
                          alt="img"
                          className="w-full h-full object-contain"
                        />
                      )}

                      {data.ad_status === "4" ? (
                        <div className="absolute bottom-5 text-white text-sm flex items-center justify-center gap-x-1 bg-red-500 right-10 md:right-5 py-1 pl-3 pr-3 rounded-lg">
                          <p className="font-medium capitalize">
                            {data.status}
                          </p>
                        </div>
                      ) : null}

                      {data.logo === "1" ? (
                        <div className="w-20 h-14 absolute left-2 top-1 overflow-hidden">
                          {data.logo_pic ? (
                            <img
                              src={data.logo_pic}
                              alt=""
                              className="w-full h-full object-contain"
                            />
                          ) : (
                            <img
                              src={classiBazarLogo}
                              alt=""
                              className="w-full h-full object-contain"
                            />
                          )}
                        </div>
                      ) : null}
                    </div>

                    <div className="w-11/12 mx-auto mt-4">
                      <div className="w-full flex items-center gap-x-3 flex-wrap">
                        {data.featured === "1" && (
                          <p className="w-fit bg-primary text-white py-1 px-2 rounded-md text-xs mt-2">
                            Featured
                          </p>
                        )}

                        {data.spoted === "1" && (
                          <p className="w-fit bg-jobPrimary text-white py-1 px-2 rounded-md text-xs mt-2">
                            Spotted
                          </p>
                        )}

                        {data.ad_status !== "4" && data.urgent === "1" ? (
                          <div className="w-fit bg-red-500 text-white py-1 px-2 rounded-md text-xs mt-2">
                            <p className="font-medium capitalize">Urgent</p>
                          </div>
                        ) : null}
                      </div>

                      <h5
                        className={`font-medium text-sm md:text-lg truncate mt-2
            ${
              category === "cars" || slugs === "cars"
                ? " text-carPrimary"
                : category === "motorcycles-scooters" ||
                  slugs === "motorcycles-scooters"
                ? " text-carPrimary"
                : category === "jobs" || slugs === "jobs"
                ? " text-jobPrimary"
                : category === "real-estate" || slugs === "real-estate"
                ? "text-realestatePrimary"
                : category === "pgflatmates" || slugs === "pgflatmates"
                ? "text-pgFlatmatePrimary"
                : " text-primary"
            }`}
                      >
                        {data.title}
                      </h5>
                      {data.show_price === "Yes" ? (
                        <h4 className="text-textPrimary font-semibold text-base md:text-lg mt-3">
                          {data.price !== "0"
                            ? data.price_type !== "2"
                              ? `$${data.price} 
                    `
                              : `$${data.price} - $${data.to_price}  
                    `
                            : ""}
                        </h4>
                      ) : null}

                      {data.cat_name === "Jobs" ? (
                        <div className="w-full flex items-center gap-x-5 mt-3">
                          <p className="w-full py-1 px-5 bg-gray-200 text-center rounded-md text-sm truncate">
                            {data.salary_type}
                          </p>
                          <p className="w-full py-1 px-5 bg-gray-200 text-center rounded-md text-sm truncate">
                            {data.job_type}
                          </p>
                        </div>
                      ) : null}
                      {/* icons */}
                      <div className="flex items-center flex-wrap gap-x-2 md:gap-x-3">
                        {data.ads_data &&
                          data.ads_data.map((item, i) => {
                            return item.value === null ||
                              item.value === "0" ||
                              item.value === " m sq." ? null : (
                              <div
                                className="flex items-center gap-x-2 mt-3"
                                key={i}
                              >
                                <img
                                  src={item.img}
                                  alt="parking"
                                  className="w-3 h-3 md:w-5 md:h-5"
                                />
                                <h6 className="text-sm truncate">
                                  {item.value}
                                </h6>
                              </div>
                            );
                          })}
                      </div>

                      <div className="w-11/12 absolute bottom-4">
                        <div className="w-full flex items-center justify-between">
                          <p className="text-textSecondary text-sm mt-3 truncate">
                            {data.suburb ? data.suburb + "," : ""}{" "}
                            {data.state_name}
                          </p>
                          <div className="hover:bg-gray-100 rounded-full p-2">
                            {data.ad_status ===
                            "4" ? null : data.is_wishlist ? (
                              <AiFillHeart
                                size={27}
                                className="text-red-500"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveWishlist(data.post_id);
                                }}
                              />
                            ) : (
                              <AiOutlineHeart
                                size={27}
                                className="text-textSecondary"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleAddToWishlist(data.post_id);
                                }}
                              />
                            )}
                          </div>
                        </div>

                        <hr className="w-full mt-2" />
                        <div className="w-full flex items-center justify-between mt-4">
                          <div className="w-full flex items-center flex-col">
                            <p className="text-sm w-full text-gray-400">
                              Posted by{" "}
                              <span className="font-medium text-black">
                                {data.sale_by}
                              </span>
                            </p>
                            <p className="text-sm w-full text-gray-400">
                              {data.posted_at}
                            </p>
                          </div>
                          <Button
                            value="View"
                            handleClick={() =>
                              navigate(
                                `/ads-detail?i=${data.post_id}&name=${data.title}`
                              )
                            }
                            className={`${
                              category === "cars" || slugs === "cars"
                                ? "border-carPrimary text-carPrimary"
                                : category === "motorcycles-scooters" ||
                                  slugs === "motorcycles-scooters"
                                ? "border-carPrimary text-carPrimary"
                                : category === "jobs" || slugs === "jobs"
                                ? "border-jobPrimary text-jobPrimary"
                                : category === "real-estate" ||
                                  slugs === "real-estate"
                                ? "border-realestatePrimary text-realestatePrimary"
                                : category === "pgflatmates" ||
                                  slugs === "pgflatmates"
                                ? "border-pgFlatmatePrimary text-pgFlatmatePrimary"
                                : "border-primary text-primary"
                            } py-2.5 w-full rounded-xl border`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
};

export default AdsCard;

export const GridAdsCard = ({
  data,
  handleAddToWishlist,
  handleRemoveWishlist,
  category,
  slugs,
}) => {
  const navigate = useNavigate();

  const location = window.location.pathname;

  return (
    <div className="w-full">
      <div
        className={`mt-10 relative rounded-xl hover:shadow-md border cursor-pointer transition-all duration-300
        ${
          location === "/jobs" || slugs === "jobs" || category === "jobs"
            ? "h-[20rem]"
            : "min-h-33rem"
        } `}
        key={data.post_id}
        onClick={() =>
          navigate(`/ads-detail?i=${data.post_id}&name=${data.title}`)
        }
      >
        <div
          className={`w-full ${
            location === "/jobs" || slugs === "jobs" || category === "jobs"
              ? "h-fit"
              : "h-52 lg:h-56 "
          } relative rounded-t-xl overflow-hidden`}
        >
          {(data.cat_name === "Jobs" || data.cat_name === "jobs") &&
          location !== "/jobs" &&
          category !== "jobs" &&
          slugs !== "jobs" ? (
            <img
              src={classiBazarLogo}
              alt="img"
              className="w-full h-full object-contain"
            />
          ) : location === "/jobs" ||
            slugs === "jobs" ||
            category === "jobs" ? null : data?.images?.[0]?.thumbnail ? (
            <img
              src={data?.images?.[0]?.thumbnail}
              alt="img"
              className="w-full h-full object-fill"
            />
          ) : (
            <img
              src={classiBazarLogo}
              alt="img"
              className="w-full h-full object-contain"
            />
          )}

          {data.ad_status === "4" ? (
            <div className="absolute bottom-5 text-white flex items-center justify-center gap-x-1 bg-red-500 right-10 md:right-5 py-1 pl-3 pr-3 rounded-lg">
              <p className="font-medium capitalize">{data.status}</p>
            </div>
          ) : null}

          {data.logo === "1" ? (
            <div className="w-20 h-14 absolute left-2 top-1 overflow-hidden">
              {data.logo_pic ? (
                <img
                  src={data.logo_pic}
                  alt=""
                  className="w-full h-full object-contain"
                />
              ) : (
                <img
                  src={classiBazarLogo}
                  alt=""
                  className="w-full h-full object-contain"
                />
              )}
            </div>
          ) : null}
        </div>
        <div className="w-11/12 mx-auto mt-4">
          <div className="w-full flex items-center gap-x-3 flex-wrap">
            {data.featured === "1" && (
              <p className="w-fit bg-primary text-white py-1 px-2 rounded-md text-xs mt-2">
                Featured
              </p>
            )}

            {data.spoted === "1" && (
              <p className="w-fit bg-jobPrimary text-white py-1 px-2 rounded-md text-xs mt-2">
                Spotted
              </p>
            )}

            {data.ad_status !== "4" && data.urgent === "1" ? (
              <div className="w-fit bg-red-500 text-white py-1 px-2 rounded-md text-xs mt-2">
                <p className="font-medium capitalize">Urgent</p>
              </div>
            ) : null}
          </div>

          <h5
            className={`font-medium text-sm md:text-lg truncate mt-2
            ${
              category === "cars" || slugs === "cars"
                ? " text-carPrimary"
                : category === "motorcycles-scooters" ||
                  slugs === "motorcycles-scooters"
                ? " text-carPrimary"
                : category === "jobs" || slugs === "jobs"
                ? " text-jobPrimary"
                : category === "real-estate" || slugs === "real-estate"
                ? "text-realestatePrimary"
                : category === "pgflatmates" || slugs === "pgflatmates"
                ? "text-pgFlatmatePrimary"
                : " text-primary"
            }`}
          >
            {data.title}
          </h5>

          {data.show_price === "Yes" ? (
            <h4 className="text-textPrimary font-semibold text-base md:text-lg mt-3">
              {data.price !== "0"
                ? data.price_type !== "2"
                  ? `$${data.price} 
                    `
                  : `$${data.price} - $${data.to_price}  
                    `
                : ""}
            </h4>
          ) : null}

          {data.cat_name === "Jobs" ? (
            <div className="w-full flex items-center gap-x-5 mt-3">
              <p className="w-fit py-1 px-5 bg-gray-200 text-center rounded-md text-sm">
                {data.salary_type}
              </p>
              <p className="w-fit py-1 px-5 bg-gray-200 text-center rounded-md text-sm">
                {data.job_type}
              </p>
            </div>
          ) : null}
          {/* icons */}
          <div className="flex flex-wrap items-center gap-x-2 md:gap-x-3">
            {data.ads_data &&
              data.ads_data.map((item, i) => {
                return item.value === null ||
                  item.value === "0" ||
                  item.value === " m sq." ? null : (
                  <div className="flex items-center gap-x-2 mt-3" key={i}>
                    <img
                      src={item.img}
                      alt="parking"
                      className="w-3 h-3 md:w-5 md:h-5"
                    />
                    <h6 className="text-sm truncate">{item.value}</h6>
                  </div>
                );
              })}
          </div>

          <div className="w-11/12 absolute bottom-4">
            <div className="w-full flex items-center justify-between">
              <p className="text-textSecondary text-sm mt-3 truncate">
                {data.suburb ? data.suburb + "," : ""} {data.state_name}
              </p>
              <div className="hover:bg-gray-100 rounded-full p-2">
                {data.ad_status === "4" ? null : data.is_wishlist ? (
                  <AiFillHeart
                    size={27}
                    className="text-red-500"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveWishlist(data.post_id);
                    }}
                  />
                ) : (
                  <AiOutlineHeart
                    size={27}
                    className="text-textSecondary"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddToWishlist(data.post_id);
                    }}
                  />
                )}
              </div>
            </div>

            <hr className="w-full mt-4" />
            <div className="w-full flex items-center justify-between mt-4">
              <div className="w-full flex items-center flex-col">
                <p className="text-sm w-full text-gray-400">
                  Posted by{" "}
                  <span className="font-medium text-black">{data.sale_by}</span>
                </p>
                <p className="text-sm w-full text-gray-400">{data.posted_at}</p>
              </div>
              <Button
                value="View"
                handleClick={() => {
                  navigate(`/ads-detail?i=${data.post_id}&name=${data.title}`);
                  scrollToTop();
                }}
                className={`${
                  category === "cars" || slugs === "cars"
                    ? "border-carPrimary text-carPrimary"
                    : category === "motorcycles-scooters" ||
                      slugs === "motorcycles-scooters"
                    ? "border-carPrimary text-carPrimary"
                    : category === "jobs" || slugs === "jobs"
                    ? "border-jobPrimary text-jobPrimary"
                    : category === "real-estate" || slugs === "real-estate"
                    ? "border-realestatePrimary text-realestatePrimary"
                    : category === "pgflatmates" || slugs === "pgflatmates"
                    ? "border-pgFlatmatePrimary text-pgFlatmatePrimary"
                    : "border-primary text-primary"
                } py-2.5 w-full rounded-xl border`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
