import React from "react";
import { useEffect } from "react";
import { scrollToTop } from "../../Component/NavigateTop";
import { BsArrowLeft } from "react-icons/bs";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Cookies from "js-cookie";
import { Breadcrum, Loading } from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetAgentEnquiryChat,
  fetchGetAgentEnquiryDetail,
  removeInboxData,
} from "../../Redux/Message/action";
import Chat from "./Chat";

const AgentEnquiry = () => {
  const { id } = useParams();

  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let userId = params.get("u");
  let userEnquiryId = params.get("i");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("token");
  const loading = useSelector((state) => state.message.isloading, shallowEqual);

  const enquiryDetail = useSelector(
    (state) => state.message.enquiryDetail,
    shallowEqual
  );

  const handleNavigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    scrollToTop();
    userId && dispatch(fetchGetAgentEnquiryDetail(id, userId, token));
    userEnquiryId &&
      dispatch(fetchGetAgentEnquiryChat(id, userEnquiryId, token));
    return () => {
      dispatch(removeInboxData());
    };
  }, [id, userId, userEnquiryId]);

  if (!token) {
    return <Navigate to={`/auth/sign-in`} replace />;
  }

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="py-16 md:py-24 h-full w-full bg-gray-50">
      <div className="w-11/12 xl:w-3/4 mx-auto mt-10">
        <Breadcrum
          title="Home"
          subTitle="My Account"
          subTitle2="Agent Enquiry"
        />

        <div
          className="text-sm md:text-base flex items-center gap-x-2 text-textPrimary cursor-pointer w-fit mt-10"
          onClick={handleNavigateBack}
        >
          <BsArrowLeft />
          <p>Go Back</p>
        </div>

        <div className="mt-10 border rounded-xl w-full overflow-hidden">
          <p className="bg-white font-medium px-5 py-3 border-b">Details</p>

          <div className="w-full flex items-center border-b text-sm">
            <p className="w-1/3 py-3 px-5">Enquiry For</p>
            <p className="w-2/3 border-l py-3 px-5 bg-white">
              {enquiryDetail.enquiry_for}
            </p>
          </div>

          <div className="w-full flex items-center border-b text-sm">
            <p className="w-1/3 px-5 py-3">Type</p>
            <p className="w-2/3 border-l px-5 py-3 bg-white">
              {enquiryDetail.nature_of_property}
            </p>
          </div>

          <div className="w-full flex items-center border-b text-sm">
            <p className="w-1/3 px-5 py-3">Property Type</p>
            <p className="w-2/3 border-l px-5 py-3 bg-white">
              {enquiryDetail.property_type}
            </p>
          </div>

          <div className="w-full flex items-center border-b text-sm">
            <p className="w-1/3 px-5 py-3">House Size</p>
            <p className="w-2/3 border-l px-5 py-3 bg-white">
              {enquiryDetail.house_size}
            </p>
          </div>

          <div className="w-full flex items-center border-b text-sm">
            <p className="w-1/3 px-5 py-3">Property Condition</p>
            <p className="w-2/3 border-l px-5 py-3 bg-white">
              {enquiryDetail.property_condition}
            </p>
          </div>
          <div className="w-full flex items-center border-b text-sm">
            <p className="w-1/3 px-5 py-3">When Needed</p>
            <p className="w-2/3 border-l px-5 py-3 bg-white">
              {enquiryDetail.when_need}
            </p>
          </div>
          <div className="w-full flex items-center text-sm">
            <p className="w-1/3 px-5 py-3">Detail</p>
            <p className="w-2/3 border-l px-5 py-3 bg-white">
              {enquiryDetail.description}
            </p>
          </div>
        </div>
        {userEnquiryId ? (
          <div className="mt-10 border rounded-xl bg-white w-full">
            <Chat id={id} userId={userId} userEnquiryId={userEnquiryId} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AgentEnquiry;
