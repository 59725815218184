import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  popular: "",
  categories: "",
  secCategories: "",
  thirdCategories: "",
  fourthCategories: "",
};

export const Categories = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_POPULAR_CATEGORY:
      return {
        ...state,
        isloading: false,
        success: true,
        popular: action.payload,
      };

    case ActionTypes.GET_ALL_CATEGORIES:
      return {
        ...state,
        isloading: false,
        success: true,
        categories: action.payload,
      };

    case ActionTypes.GET_SECOND_LEVEL_CATEGORIES:
      return {
        ...state,
        isloading: false,
        success: true,
        secCategories: action.payload,
      };

    case ActionTypes.GET_THIRD_LEVEL_CATEGORIES:
      return {
        ...state,
        isloading: false,
        success: true,
        thirdCategories: action.payload,
      };

    case ActionTypes.GET_FOURTH_LEVEL_CATEGORIES:
      return {
        ...state,
        isloading: false,
        success: true,
        fourthCategories: action.payload,
      };

    case ActionTypes.REMOVE_CATEGORY_DATA:
      return {
        isloading: true,
        success: false,
        popular: "",
        categories: "",
        secCategories: "",
        thirdCategories: "",
        fourthCategories: "",
      };

    default:
      return state;
  }
};
