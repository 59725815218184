import React from "react";
import { advertisement, leftAd, topAd } from "../../Assets/Assest";

const Advertisement = ({ className }) => {
  return (
    <div className={className}>
      <img src={advertisement} alt="" className="w-full h-full object-fill" />
    </div>
  );
};

export default Advertisement;

export const LeftAdvertisement = ({ className }) => {
  return (
    <div className={className}>
      <img src={leftAd} alt="" className="w-full h-full object-fill" />
    </div>
  );
};

export const TopAdvertisement = ({ className }) => {
  return (
    <div className={className}>
      <img src={topAd} alt="" className="w-full h-full object-fill" />
    </div>
  );
};
