import React, { useState } from "react";
import { IoIosAdd } from "react-icons/io";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RiDeleteBin6Line } from "react-icons/ri";
import Cookies from "js-cookie";
import {
  fetchAddTodo,
  fetchChangeTodoStatus,
  fetchDeleteTodo,
} from "../../Redux/Dashboard/action";
import { Button } from "../../Component";

const Todo = () => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const todo = useSelector((state) => state.dashboard.todos, shallowEqual);

  const handleAddTodo = () => {
    const body = {
      todos: value,
    };

    dispatch(fetchAddTodo(body, token));
  };

  const handleClickCheckbox = (e, id) => {
    const { checked } = e.target;
    if (checked) {
      const body = {
        todo_id: id,
        status: 1,
      };
      dispatch(fetchChangeTodoStatus(body, token));
    } else {
      const body = {
        todo_id: id,
        status: 0,
      };

      dispatch(fetchChangeTodoStatus(body, token));
    }
  };

  const handleClickDelete = (id) => {
    const body = {
      todo_id: id,
      status: 1,
    };
    dispatch(fetchDeleteTodo(body, token));
  };

  return (
    <div className="w-full h-[26rem] overflow-auto border rounded-md bg-white">
      <div className="w-full sticky top-0">
        <div className="w-full flex items-center justify-between p-3  bg-white ">
          <h6 className="font-medium">To Do Task</h6>
        </div>
        <hr className="w-full" />

        <div className="w-full flex items-center justify-between p-3 bg-white">
          <input
            type="text"
            placeholder="Add your task here.."
            onChange={(e) => setValue(e.target.value)}
            className="w-3/4 border rounded-md py-2 px-3 text-sm outline-none"
          />

          <Button
            value="Add"
            handleClick={handleAddTodo}
            className="text-white rounded-xl text-sm py-2 px-5 bg-primary flex items-center cursor-pointer"
          />
        </div>
        <hr className="w-full" />
      </div>

      <div className="w-full mt-3">
        {todo &&
          todo.map((val) => {
            return (
              <div
                className="flex w-full justify-between p-3 border-b mt-3"
                key={val.id}
              >
                <div className="w-11/12 flex gap-x-3">
                  <input
                    type="checkbox"
                    className="w-4 h-4"
                    checked={val.status === "1"}
                    onChange={(e) => handleClickCheckbox(e, val.id)}
                  />
                  <p
                    className={`${
                      val.status === "1" ? "line-through" : ""
                    } text-sm capitalize w-full`}
                  >
                    {val.task}
                  </p>
                </div>
                <RiDeleteBin6Line
                  className="text-red-700 text-xl cursor-pointer"
                  onClick={() => handleClickDelete(val.id)}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Todo;
