import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { Button } from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  fetchChangeAdStatus,
  fetchMakeAdCompleted,
  removeAdsData,
} from "../../Redux/Ads/action";
import { toast } from "react-toastify";
import { fetchGetMyAds } from "../../Redux/MyAds/action";
import { useLocation } from "react-router-dom";

const ChangeStatus = ({
  setShowStatusPopup,
  status,
  setStatus,
  setSelectedPostId,
  selectedPostId,
  toggle,
}) => {
  const location = useLocation();
  let params = new URLSearchParams(location.search);

  let pg = params.get("page");
  const [page, setPage] = useState(pg ? Number(pg) : 1);
  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const message = useSelector((state) => state.ads.message, shallowEqual);
  const errMsg = useSelector((state) => state.ads.errMsg, shallowEqual);

  const handleMakeComplete = () => {
    dispatch(
      fetchMakeAdCompleted(status === "Active" ? 1 : 0, selectedPostId, token)
    );
  };

  const handleChangeStatus = () => {
    dispatch(
      fetchChangeAdStatus(status === "Active" ? 0 : 1, selectedPostId, token)
    );
  };

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeAdsData());
    message && setShowStatusPopup(false);
    message && setSelectedPostId("");
    message && setStatus("");
    setTimeout(() => {
      message && dispatch(fetchGetMyAds(page, toggle, token));
    }, 100);
  }, [message]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    errMsg && dispatch(removeAdsData());
    setTimeout(() => {
      errMsg && dispatch(fetchGetMyAds(page, toggle, token));
    }, 100);
  }, [errMsg]);
  return (
    <div className="absolute top-10 border rounded-2xl z-40 bg-white shadow-2xl w-11/12 lg:w-3/4 left-[5%] lg:left-[10%] py-5">
      <div className="px-5">
        <div className="flex w-full items-center justify-between">
          <p className="font-medium">
            Are you sure you want to change ad status?
          </p>
          <div
            className="w-fit p-2 rounded-lg cursor-pointer hover:bg-gray-100"
            onClick={() => {
              setShowStatusPopup(false);
              setSelectedPostId("");
              setStatus("");
            }}
          >
            <IoMdClose className="text-lg text-gray-600" />
          </div>
        </div>

        <div className="w-full flex items-center justify-between gap-x-5 mt-5">
          {status === "Active" ? (
            <Button
              value="Inactive"
              handleClick={handleChangeStatus}
              className="w-full border py-3 text-sm rounded-xl bg-carPrimary text-white"
            />
          ) : status === "Inactive" ? (
            <Button
              value="Active"
              handleClick={handleChangeStatus}
              className="w-full border py-3 text-sm rounded-xl bg-jobPrimary text-white"
            />
          ) : null}

          <Button
            value="Completed"
            handleClick={handleMakeComplete}
            className="w-full text-sm py-3 rounded-xl bg-primary text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default ChangeStatus;
