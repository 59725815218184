import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  views: "",
  myAds: [],
  totalData: "",
  data: "",
  formId: "",
  title: [],
  fields: [],
  field: [],
  images: [],
  inspection_times: [],
  post: "",
  message: "",
  errMsg: "",
  allInspection: [],
  todayInspection: [],
  tomorrowInspection: [],
  weekInspection: [],
  monthInspection: [],
  durations: [],
  reason: [],
  classi_credit_value: "",
};

export const MyAds = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_TOTAL_VIEWS:
      return {
        ...state,
        isloading: false,
        success: true,
        views: action.payload,
      };

    case ActionTypes.GET_MY_ADS:
      return {
        ...state,
        isloading: false,
        success: true,
        myAds: action.payload.posts,
        totalData: action.payload.all_ads,
        data: action.payload,
      };

    case ActionTypes.GET_ADS_FORM:
      return {
        ...state,
        isloading: false,
        success: true,
        formId: action.payload.fields.form_id,
        title: action.payload.fields.categories.titles,
        fields: action.payload.fields.static_fields,
        field: action.payload.fields.fields,
      };

    case ActionTypes.GET_EDIT_FORM:
      return {
        ...state,
        isloading: false,
        success: true,
        formId: action.payload.data.form_fields.form_id,
        title: action.payload.data.form_fields.categories.titles,
        fields: action.payload.data.form_fields.static_fields,
        field: action.payload.data.form_fields.fields,
        images: action.payload.data.images,
        post: action.payload.data.post,
        inspection_times: action.payload.data.inspection_times,
      };

    case ActionTypes.POST_ADS_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.data.message,
      };

    case ActionTypes.POST_ADS_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.EDIT_ADS_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.data.message,
      };

    case ActionTypes.EDIT_ADS_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.GET_MY_INSPECTION:
      return {
        ...state,
        isloading: false,
        success: true,
        allInspection: action.payload.inspections,
        todayInspection: action.payload.today_inspection,
        tomorrowInspection: action.payload.tomorrow_inspection,
        weekInspection: action.payload.week_inspection,
        monthInspection: action.payload.month_inspection,
      };

    case ActionTypes.GET_PAYMENT_TYPES:
      return {
        ...state,
        isloading: false,
        success: true,
        durations: action.payload.durations,
        classi_credit_value: action.payload.classi_credit_value,
      };

    case ActionTypes.GET_DELETE_REASON:
      return {
        ...state,
        isloading: false,
        success: true,
        reason: action.payload,
      };

    case ActionTypes.DELETE_POST_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.DELETE_POST_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.REMOVE_MY_ADS_DATA:
      return {
        isloading: true,
        success: false,
        views: "",
        myAds: [],
        totalData: "",
        data: "",
        formId: "",
        title: "",
        fields: "",
        message: "",
        errMsg: "",
        field: "",
        images: [],
        post: "",
        inspection_times: [],
        allInspection: [],
        todayInspection: [],
        tomorrowInspection: [],
        weekInspection: [],
        monthInspection: [],
        classi_credit_value: "",
      };

    default:
      return state;
  }
};
