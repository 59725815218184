import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { FaMoneyBills } from "react-icons/fa6";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchDeactivateAccount,
  fetchGetDeactivateReason,
  fetchGetPrivacySetting,
  fetchUpdatePrivacySetting,
  removePrivacyData,
} from "../../Redux/PrivacySetting/action";
import { Button, FormikControl, Loading } from "../../Component";
import { toast } from "react-toastify";
import { ErrorMessage, Field, Form, Formik } from "formik";
import TextError from "../../Component/Formik/Error";
import * as Yup from "yup";
import { scrollToTop } from "../../Component/NavigateTop";

const PrivacySetting = () => {
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const loading = useSelector((state) => state.privacy.isloading, shallowEqual);
  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  const message = useSelector((state) => state.privacy.message, shallowEqual);
  const errMsg = useSelector((state) => state.privacy.errMsg, shallowEqual);
  const reasons = useSelector((state) => state.privacy.reasons, shallowEqual);
  const privacySetting = useSelector(
    (state) => state.privacy.privacySetting,
    shallowEqual
  );
  const deactivateMsg = useSelector(
    (state) => state.privacy.deactivateMsg,
    shallowEqual
  );
  const deactivateErrMsg = useSelector(
    (state) => state.privacy.deactivateErrMsg,
    shallowEqual
  );

  const [formData, setFormData] = useState(privacySetting);

  const handleCheckboxChange = (settingKey) => {
    setFormData((prevData) => ({
      ...prevData,
      [settingKey]: prevData[settingKey] === "1" ? "0" : "1",
    }));
  };

  const initialValues = {
    reason: "",
    description: "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    reason: Yup.string().required("Required*"),
    description: Yup.string().required("Required*"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    scrollToTop();
    console.log("valuesss", values);
    dispatch(fetchDeactivateAccount(values, token));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm({ value: "" });
    }, 1000);
  };

  useEffect(() => {
    setFormData(privacySetting);
  }, [privacySetting]);

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetPrivacySetting(token));
    dispatch(fetchGetDeactivateReason(token));
    return () => {
      dispatch(removePrivacyData());
    };
  }, []);

  useEffect(() => {
    message && toast.success(message);
    errMsg &&
      toast.error(errMsg)(message || errMsg) &&
      dispatch(removePrivacyData());
    setTimeout(() => {
      (message || errMsg) && dispatch(fetchGetPrivacySetting(token));
      (message || errMsg) && dispatch(fetchGetDeactivateReason(token));
    }, 100);
  }, [errMsg, message]);

  useEffect(() => {
    deactivateMsg && toast.success(deactivateMsg);
    deactivateErrMsg &&
      toast.error(deactivateErrMsg)(deactivateErrMsg || deactivateMsg) &&
      dispatch(removePrivacyData());
    setTimeout(() => {
      (deactivateMsg || deactivateErrMsg) &&
        dispatch(fetchGetPrivacySetting(token));
      (deactivateMsg || deactivateErrMsg) &&
        dispatch(fetchGetDeactivateReason(token));
      deactivateMsg && Cookies.remove("token");
      deactivateMsg && window.location.reload(false);
    }, 100);
  }, [deactivateErrMsg, deactivateMsg]);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-full h-full mt-2 relative">
      <div className="w-full flex items-center gap-x-3">
        <h6 className="text-2xl font-semibold">Privacy Setting</h6>
        <div className="w-fit py-1 px-3 flex items-center gap-x-3 border rounded-full bg-white">
          <FaMoneyBills className="text-xl text-primary" />
          <p className="font-semibold">{profile.classi_credit} Coins</p>
        </div>
      </div>

      <div className="w-full bg-white border rounded-xl mt-10">
        <p className="font-medium p-3">Privacy Settings</p>
        <hr className="w-full" />

        <div className="w-full p-3 ">
          {Object.keys(formData)
            .filter(
              (key) =>
                key !== "setting_id" &&
                key !== "property_updates" &&
                key !== "sale_result" &&
                key !== "user_id"
            )
            .map((settingKey) => (
              <div
                key={settingKey}
                className="w-full flex items-center gap-x-3 mt-3"
              >
                <input
                  type="checkbox"
                  className="w-4 h-4"
                  checked={formData[settingKey] === "1"}
                  onChange={() => handleCheckboxChange(settingKey)}
                />
                <p className="text-sm">
                  {settingKey === "profile_photo"
                    ? "Make My Profile Photo Public"
                    : settingKey === "monthly_newsletter"
                    ? "I Want To Receive Monthly Newsletter"
                    : settingKey === "email_notification_offer"
                    ? "I Want To Receive E-Mail Notifications Of Offers/Messages"
                    : settingKey === "email_alert_new_listing"
                    ? "I Want To Receive E-Mail Alerts About New Listings"
                    : settingKey === "enable_offer"
                    ? "Enable Offers/Messages Option In All My Ads Post"
                    : settingKey === "push_notification"
                    ? "Enable Daily Mail"
                    : ""}
                  :
                </p>
              </div>
            ))}
          {/* <button onClick={handleUpdate}>Update</button> */}
        </div>

        <hr className="w-full" />
        <div className="w-full flex items-center justify-end p-3">
          <Button
            value="Save Changes"
            handleClick={() => {
              dispatch(fetchUpdatePrivacySetting(formData, token));
            }}
            className="w-fit bg-primary text-white rounded-xl px-7 py-3 text-sm "
          />
        </div>
      </div>

      {/* deactive account */}
      <div className="w-full bg-white border rounded-xl mt-10">
        <p className="font-medium p-3">Deactivate Account</p>
        <hr className="w-full" />

        <div className="mt-3">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount
          >
            {(formik) => (
              <Form>
                <div className="text-sm p-3">
                  <label className="pb-2 font-medium flex text-textPrimary text-sm items-center justify-between">
                    Reason for Deactivate{" "}
                    <ErrorMessage name="reasons" component={TextError} />
                  </label>

                  <Field
                    as="select"
                    name="reason"
                    className="w-full px-2 py-3 border rounded-lg outline-gray-300 text-secondary"
                  >
                    <option value="" selected disabled>
                      Select reason
                    </option>
                    {reasons.map((val) => {
                      return (
                        <option key={val.reason_id} value={val.reason_id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </Field>
                </div>

                <div className="mt-1 w-full p-3">
                  <FormikControl
                    control="textarea"
                    placeholder="Write your message here..."
                    label="Do you have anything to say? Feel free to say."
                    name="description"
                  />
                </div>
                <hr className="w-full" />
                <div className="w-full flex items-center p-3 justify-end">
                  <Button
                    value="Deactivate Account"
                    className="w-fit px-7 py-3 text-sm bg-carPrimary text-white rounded-xl"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default PrivacySetting;
