import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const jobsLoading = () => ({
  type: ActionTypes.JOBS_LOADING,
});

export const removeJobsData = () => ({
  type: ActionTypes.REMOVE_JOBS_DATA,
});

export const fetchGetJobsCategories = () => async (dispatch) => {
  await axios.get(`${BaseUrl}/job_category`).then(({ data }) => {
    dispatch({
      type: ActionTypes.GET_JOBS_CATEGORIES,
      payload: data,
    });
  });
};
