import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";
import Cookies from "js-cookie";

export const authLoading = () => ({
  type: ActionTypes.AUTH_LOADING,
});

export const removeAuthData = () => ({
  type: ActionTypes.REMOVE_AUTH_DATA,
});

export const fetchSocialLogin = (body) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/social_authenticate`, body)
    .then(({ data }) => {
      Cookies.set("token", data.access_token, {
        path: "/",
      });
      dispatch({
        type: ActionTypes.SOCIAL_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.SOCIAL_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchLogin = (body) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/authenticate`, body)
    .then(({ data }) => {
      Cookies.set("token", data.access_token, {
        path: "/",
      });
      dispatch({
        type: ActionTypes.LOGIN_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.LOGIN_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchSignup = (body) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/signup`, body)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SIGNUP_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.SIGNUP_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchOTPVerify = (body) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/activate_user`, body)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.OTP_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.OTP_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchResendOTP = (body) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/resend_activation_code`, body)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.RESEND_OTP_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.RESEND_OTP_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchForgotPassword = (email) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/forgot_password`, email)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.FORGOT_PASSWORD_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FORGOT_PASSWORD_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchChangePassword = (body) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/change_password`, body)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.CHANGE_PASSWORD_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.CHANGE_PASSWORD_FAIL,
        payload: e.response.data,
      });
    });
};
