import moment from "moment";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";

const MyActivity = () => {
  const activities = useSelector(
    (state) => state.dashboard.activity,
    shallowEqual
  );
  return (
    <div className="w-full h-[26rem] overflow-auto border rounded-md bg-white">
      <div className="w-full sticky top-0">
        <div className="w-full flex items-center justify-between p-3  bg-white ">
          <h6 className="font-medium">Activity Log</h6>
        </div>
        <hr className="w-full" />
      </div>
      <div className="p-3">
        {activities &&
          activities.map((val, i) => {
            return (
              <div className="mt-3 w-full flex items-center gap-x-5" key={i}>
                <input type="radio" />
                <div>
                  <p className="text-sm">{val.title}</p>
                  <p className="text-xs mt-1 text-gray-400">
                    {moment.utc(val.gmt).local().fromNow()}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MyActivity;
