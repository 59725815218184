import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  spotted: [],
  ads: "",
  detail: "",
  totalData: "",
  meta: "",
  filters: "",
  message: "",
  errMsg: "",
  searchTags: "",
  searchData: [],
  parentId: "",
};

export const Ads = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_SPOTLIGHT:
      return {
        ...state,
        isloading: false,
        success: true,
        spotted: action.payload,
      };
    case ActionTypes.GET_ADS:
      return {
        ...state,
        isloading: false,
        success: true,
        ads: action.payload.posts,
        totalData: action.payload.total_numbers,
        meta: action.payload.meta,
        filters: action.payload.filters?.static_fields,
        searchTags: action.payload.search_tags?.[0],
        parentId: action.payload.parent_id,
        searchData: action.payload.search_tags,
      };

    case ActionTypes.GET_FILTERED_ADS:
      return {
        ...state,
        isloading: false,
        success: true,
        ads: action.payload.posts,
        totalData: action.payload.total_numbers,
        meta: action.payload.meta,
        filters: action.payload.filters?.static_fields,
        searchTags: action.payload.search_tags?.[0],
        parentId: action.payload.parent_id,
        searchData: action.payload.search_tags,
      };

    case ActionTypes.ADS_DETAIL:
      return {
        ...state,
        isloading: false,
        success: true,
        detail: action.payload,
      };

    case ActionTypes.ADD_INSPECTION_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: "Inspection plan added successfully",
      };

    case ActionTypes.ADD_INSPECTION_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.DELETE_INSPECTION_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: "Inspection plan remove successfully",
      };

    case ActionTypes.DELETE_INSPECTION_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.RESPOND_AD_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.RESPOND_AD_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.REPORT_ADS_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.data.message,
      };

    case ActionTypes.REPORT_ADS_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.MAKE_AD_COMPLETED_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.MAKE_AD_COMPLETED_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.CHANGE_AD_STATUS_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.CHANGE_AD_STATUS_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.SAVE_SEARCH_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.SAVE_SEARCH_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.DELETE_SAVE_SEARCH_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.DELETE_SAVE_SEARCH_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.REVOME_ADS:
      return {
        isloading: true,
        success: false,
        ads: "",
        totalData: "",
        meta: "",
        detail: "",
        filters: "",
        message: "",
        errMsg: "",
        searchTags: "",
        parentId: "",
        spotted: [],
        searchData: [],
      };

    default:
      return state;
  }
};
