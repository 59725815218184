import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  location: [],
};

export const Location = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_LOCATION_BY_STATE:
      return {
        ...state,
        isloading: false,
        success: true,
        location: action.payload,
      };

    case ActionTypes.REMOVE_LOCATION_DATA:
      return {
        isloading: true,
        success: false,
        location: [],
      };

    default:
      return state;
  }
};
