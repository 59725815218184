import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { GridAdsCard } from "../../Component";

const GridSearch = ({
  category,
  slugs,
  handleAddToWishlist,
  handleRemoveWishlist,
}) => {
  const ads = useSelector((state) => state.ads.ads, shallowEqual);
  return (
    <div className="w-full grid grid-cols-1 pb-5 sm:grid-cols-1 md:grid-cols-2 gap-x-3 xl:gap-x-6">
      {ads &&
        ads.map((val) => {
          return (
            <div key={val.post_id}>
              <GridAdsCard
                data={val}
                category={category}
                slugs={slugs}
                handleAddToWishlist={handleAddToWishlist}
                handleRemoveWishlist={handleRemoveWishlist}
              />
            </div>
          );
        })}
    </div>
  );
};

export default GridSearch;
