import React from "react";
import { useEffect } from "react";
import { FaMoneyBills } from "react-icons/fa6";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { scrollToTop } from "../../Component/NavigateTop";
import Cookies from "js-cookie";
import {
  fetchGetMyWishlist,
  fetchRemoveWishlistByPostId,
  removeWishlistData,
} from "../../Redux/Wishlist/action";
import {
  Loading,
  MyWishlistCard,
  Pagination,
  SinglePagnination,
} from "../../Component";
import { toast } from "react-toastify";
import { useState } from "react";

const Wishlist = () => {
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  let params = new URLSearchParams(document.location.search);
  let pg = params.get("page");
  const [page, setPage] = useState(pg ? Number(pg) : 1);

  const loading = useSelector(
    (state) => state.wishlist.isloading,
    shallowEqual
  );
  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  const wishlist = useSelector(
    (state) => state.wishlist.wishlist,
    shallowEqual
  );
  const totalData = useSelector(
    (state) => state.wishlist.totalData,
    shallowEqual
  );

  const message = useSelector((state) => state.wishlist.message, shallowEqual);
  const errMsg = useSelector((state) => state.wishlist.errMsg, shallowEqual);

  const totalPage = Math.ceil(totalData / 10);

  const handleRemoveWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchRemoveWishlistByPostId(body, token));
  };

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeWishlistData());
    setTimeout(() => {
      message && dispatch(fetchGetMyWishlist(page, token));
    }, 100);
  }, [message]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    errMsg && dispatch(removeWishlistData());
    setTimeout(() => {
      errMsg && dispatch(fetchGetMyWishlist(page, token));
    }, 100);
  }, [errMsg]);

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetMyWishlist(page, token));
    return () => {
      dispatch(removeWishlistData());
    };
  }, [page]);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-full h-full mt-2 relative">
      <div className="w-full flex items-center gap-x-3">
        <h6 className="text-2xl font-semibold">My Favourite</h6>
        <div className="w-fit py-1 px-3 flex items-center gap-x-3 border rounded-full bg-white">
          <FaMoneyBills className="text-xl text-primary" />
          <p className="font-semibold">{profile.classi_credit} Coins</p>
        </div>
      </div>

      <div className="mt-10 w-full">
        {wishlist && wishlist.length ? (
          <>
            {wishlist.map((val) => {
              return (
                <MyWishlistCard
                  key={val.wishlist_id}
                  val={val}
                  handleRemoveWishlist={handleRemoveWishlist}
                />
              );
            })}
            <div className="mt-10">
              {totalPage > 5 ? (
                <Pagination
                  setPage={setPage}
                  page={page}
                  totalData={totalData}
                  totalPage={totalPage}
                />
              ) : (
                <SinglePagnination
                  setPage={setPage}
                  page={page}
                  totalData={totalData}
                  totalPage={totalPage}
                />
              )}
            </div>
          </>
        ) : (
          <h1>Sorry!! You haven't add post to your wishlist</h1>
        )}
      </div>
    </div>
  );
};

export default Wishlist;
