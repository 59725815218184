import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import { BiUserCircle } from "react-icons/bi";
import Cookies from "js-cookie";
import { AiOutlineClose } from "react-icons/ai";

const MobileMenu = ({ menu, setMobileMenu, mobileMenu }) => {
  const navigate = useNavigate();
  const token = Cookies.get("token");

  const handleClickLogout = () => {
    Cookies.remove("token");
    window.location.reload(false);
  };
  return (
    <div
      className={` bg-headerColor fixed right-0 top-0 pb-10 h-full w-2/3 sm:w-2/5 shadow-2xl backdrop-blur-2xl transform z-50 ease-in-out duration-500 overflow-y-auto  ${
        mobileMenu ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="flex justify-end py-4 px-5">
        <div
          className="hover:border cursor-pointer bg-footerColor rounded-full p-2"
          onClick={() => setMobileMenu(false)}
        >
          <AiOutlineClose className="text-xl sm:text-2xl md:text-3xl text-white" />
        </div>
      </div>

      <div className="flex flex-col mt-6 gap-y-5 w-full z-50">
        {menu.map((val) => {
          return (
            <NavLink
              key={val.id}
              className="text-base md:text-lg px-7 text-white hover:underline"
              to={val.link}
              onClick={() => setMobileMenu(false)}
            >
              {val.name}
            </NavLink>
          );
        })}

        <hr className="mt-4" />
        <div className="w-full px-7">
          {token ? (
            <div className="flex flex-col gap-y-5 px-5 h-full">
              <Button
                value="My Account"
                handleClick={() => {
                  navigate("/me");
                  setMobileMenu(false);
                }}
                className="rounded-xl py-4 bg-primary text-white"
              />

              <Button
                value="Logout"
                handleClick={handleClickLogout}
                className="border py-4 border-primary text-primary rounded-xl"
              />
            </div>
          ) : (
            <div className="flex items-center h-full gap-x-4 w-fit px-5 py-3 border rounded-xl">
              <BiUserCircle className="text-white text-lg" />
              <Button
                value="Login"
                className="text-white hover:underline text-sm w-fit"
                handleClick={() => {
                  navigate("/auth/sign-in");
                  setMobileMenu(false);
                }}
              />
              <p className="text-sm text-white">/</p>
              <Button
                value="Register"
                className="text-sm text-white w-fit hover:underline"
                handleClick={() => {
                  navigate("/auth/sign-up");
                  setMobileMenu(false);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
