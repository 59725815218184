import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const removeRequestData = () => ({
  type: ActionTypes.REMOVE_REQUEST_DATA,
});

export const fetchSendAPiRequest = (body) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/api_request`, body)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SEND_API_REQUEST_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.SEND_API_REQUEST_FAIL,
        payload: e?.response.data,
      });
    });
};

export const fetchGetClassisupplierRequest = () => async (dispatch) => {
  await axios.get(`${BaseUrl}/classi_supplier_request`).then(({ data }) => {
    dispatch({
      type: ActionTypes.GET_CLASSISUPPLIER_REQUEST,
      payload: data,
    });
  });
};

export const fetchSendClassiSupplierRequest = (body) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/classi_supplier_request`, body)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SEND_CLASSISUPPLIER_REQUEST_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.SEND_CLASSISUPPLIER_REQUEST_FAIL,
        payload: e?.response?.data,
      });
    });
};
