import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { classiBazarLogo } from "../../Assets/Assest";
import { fetchDeleteInspection } from "../../Redux/Ads/action";

const MyInspectionCard = ({ myInspection }) => {
  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const navigate = useNavigate();

  const handleDeleteInspection = (e, id) => {
    e.stopPropagation();
    const body = {
      inspection_id: id,
    };
    dispatch(fetchDeleteInspection(body, token));
  };
  return myInspection && myInspection.length
    ? myInspection.map((val, i) => {
        return val.inspection_times.map((itm) => {
          return (
            <div
              className="border rounded-xl p-5 mt-5 hover:shadow-md cursor-pointer"
              key={i}
              onClick={() =>
                navigate(`/ads-detail?i=${itm.post_id}&name=${val.title}`)
              }
            >
              <div className="flex gap-x-5">
                <div className="w-40 h-20 md:w-48 md:h-32">
                  {val.thumbnail ? (
                    <img
                      src={val.thumbnail}
                      alt=""
                      className="w-full h-full rounded-lg"
                    />
                  ) : (
                    <img
                      src={classiBazarLogo}
                      alt=""
                      className="w-full h-full rounded-lg"
                    />
                  )}
                </div>
                <div className="w-full h-full">
                  <h6 className="font-semibold text-sm md:text-base lg:text-lg text-primary">
                    {val.title}
                  </h6>
                  <div className="w-full flex items-center justify-between mt-1">
                    <h6 className="font-semibold text-sm md:text-base lg:text-lg text-textPrimary">
                      {itm.inspection_date}
                    </h6>
                    <div
                      onClick={(e) =>
                        handleDeleteInspection(e, itm.inspection_id)
                      }
                      className="p-2 rounded-full hover:bg-gray-100 cursor-pointer transition duration-700 ease-in-out"
                    >
                      <RiDeleteBin6Line className="text-sm text-textSecondary md:text-lg" />
                    </div>
                  </div>

                  <h6 className="font-medium mt-1 md:mt-2 text-xs md:text-sm lg:text-base text-textPrimary">
                    {itm.start_hr}:{itm.start_min} - {itm.end_hr}:{itm.end_min}
                  </h6>
                  <p className="text-[0.5rem] md:text-sm text-textSecondary mt-3">
                    {val.ad_address}
                  </p>
                  {/* icons */}
                  <div className="flex flex-wrap items-center gap-x-3 md:gap-x-5 mt-2 md:mt-4">
                    {val.ads_data &&
                      val.ads_data.map((item, i) => {
                        return item.value === null ||
                          item.value === "0" ||
                          item.value === " m sq." ? null : (
                          <div className="flex items-center gap-x-2" key={i}>
                            <img
                              src={item.img}
                              alt="parking"
                              className="w-3 h-3 md:w-5 md:h-5"
                            />
                            <h6 className="text-textPrimary text-[0.6rem] md:text-base lg:text-lg">
                              {item.value}
                            </h6>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          );
        });
      })
    : null;
};

export default MyInspectionCard;
