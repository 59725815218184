import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FaMoneyBills } from "react-icons/fa6";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchGetDeleteReason,
  fetchGetMyAds,
  removeMyAdsData,
} from "../../Redux/MyAds/action";
import Cookies from "js-cookie";
import {
  Loading,
  MyAdsCard,
  Pagination,
  SinglePagnination,
} from "../../Component";
import { scrollToTop } from "../../Component/NavigateTop";
import UpgradePost from "./UpgradePost";
import DeletePost from "./DeletePost";
import { removeLogoData } from "../../Redux/Logo/action";

const MyAds = () => {
  const [post, setPost] = useState({
    id: "",
    name: "",
  });
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showStatusPopup, setShowStatusPopup] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let status = params.get("status");
  let upgrade = params.get("upgrade");
  let pg = params.get("page");
  const [page, setPage] = useState(pg ? Number(pg) : 1);
  const [toggle, setToggle] = useState(status ? status : "");
  const [totalPage, setTotalPage] = useState();

  const token = Cookies.get("token");

  const profileLoading = useSelector(
    (state) => state.profile.isloading,
    shallowEqual
  );
  const loading = useSelector((state) => state.myAds.isloading, shallowEqual);
  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  const data = useSelector((state) => state.myAds.data, shallowEqual);
  const myAds = useSelector((state) => state.myAds.myAds, shallowEqual);

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetMyAds(page, toggle, token));

    return () => {
      dispatch(removeMyAdsData());
      dispatch(removeLogoData());
    };
  }, [toggle, page]);

  useEffect(() => {
    toggle === "Active"
      ? setTotalPage(Math.ceil(data.active_ads / 10))
      : toggle === "Inactive"
      ? setTotalPage(Math.ceil(data.inactive_ads / 10))
      : toggle === "Featured"
      ? setTotalPage(Math.ceil(data.featured_ads / 10))
      : toggle === "Completed"
      ? setTotalPage(Math.ceil(data.completed_ads / 10))
      : setTotalPage(Math.ceil(data.all_ads / 10));
  }, [data && status]);

  useEffect(() => {
    dispatch(fetchGetDeleteReason(token));
  }, [toggle]);

  if (loading || toggle === null || profileLoading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-full h-full mt-2 relative">
      {showDeletePopup && (
        <DeletePost
          post={post}
          setPost={setPost}
          setShowDeletePopup={setShowDeletePopup}
          page={page}
          toggle={toggle}
        />
      )}
      {showDeletePopup || showStatusPopup ? (
        <div
          className="w-full absolute h-full bg-black opacity-10 z-30"
          onClick={() => {
            setShowDeletePopup(false);
            setShowStatusPopup(false);
          }}
        >
          {" "}
        </div>
      ) : null}
      <div className="w-full flex items-center gap-x-3">
        <h6 className="text-2xl font-semibold">My Ads</h6>
        <div className="w-fit py-1 px-3 flex items-center gap-x-3 border rounded-full bg-white">
          <FaMoneyBills className="text-xl text-primary" />
          <p className="font-semibold">{profile.classi_credit} Coins</p>
        </div>
      </div>
      {upgrade ? (
        <UpgradePost id={upgrade} />
      ) : (
        <>
          {/* header */}
          <div className="w-full flex items-center gap-x-5 mt-4 border-b overflow-y-auto">
            <div
              className={`flex items-center gap-x-2 lg:gap-x-5 w-full justify-center text-sm py-5 cursor-pointer ${
                status === null ? "border-b-2 border-primary" : ""
              }`}
              onClick={() => {
                setToggle("");
                navigate(`/me/my-ads`);
              }}
            >
              <p className={`${status === null ? "" : "text-gray-500"}`}>All</p>
              <p className="rounded-lg text-xs lg:p-1 px-1 lg:px-3 bg-gray-200 w-fit">
                {data.all_ads}
              </p>
            </div>

            <div
              className={`flex items-center gap-x-2 lg:gap-x-5 w-full justify-center text-sm py-5 cursor-pointer ${
                status === "Featured" ? "border-b-2 border-primary" : ""
              }`}
              onClick={() => {
                setToggle("Featured");
                navigate(`/me/my-ads?status=Featured`);
              }}
            >
              <p className={`${status === "Featured" ? "" : "text-gray-500"}`}>
                Featured
              </p>
              <p className="rounded-lg text-xs lg:p-1 px-1 lg:px-3 bg-gray-200 w-fit">
                {data.featured_ads}
              </p>
            </div>

            <div
              className={`flex items-center gap-x-2 lg:gap-x-5 w-full justify-center text-sm py-5 cursor-pointer ${
                status === "Active" ? "border-b-2 border-primary" : ""
              }`}
              onClick={() => {
                setToggle("Active");
                navigate(`/me/my-ads?status=Active`);
              }}
            >
              <p className={`${status === "Active" ? "" : "text-gray-500"}`}>
                Active
              </p>
              <p className="rounded-lg text-xs lg:p-1 px-1 lg:px-3 bg-gray-200 w-fit">
                {data.active_ads}
              </p>
            </div>

            <div
              className={`flex items-center gap-x-2 lg:gap-x-5 w-full justify-center text-sm py-5 cursor-pointer ${
                status === "Inactive" ? "border-b-2 border-primary" : ""
              }`}
              onClick={() => {
                setToggle("Inactive");
                navigate(`/me/my-ads?status=Inactive`);
              }}
            >
              <p className={`${status === "Inactive" ? "" : "text-gray-500"}`}>
                Inactive
              </p>
              <p className="rounded-lg text-xs lg:p-1 px-1 lg:px-3 bg-gray-200 w-fit">
                {data.inactive_ads}
              </p>
            </div>

            <div
              className={`flex items-center gap-x-2 lg:gap-x-5 w-full justify-center text-sm py-5 cursor-pointer ${
                status === "Completed" ? "border-b-2 border-primary" : ""
              }`}
              onClick={() => {
                setToggle("Completed");
                navigate(`/me/my-ads?status=Completed`);
              }}
            >
              <p className={`${status === "Completed" ? "" : "text-gray-500"}`}>
                Completed
              </p>
              <p className="rounded-lg text-xs lg:p-1 px-1 lg:px-3 bg-gray-200 w-fit">
                {data.completed_ads}
              </p>
            </div>
          </div>

          {myAds && myAds.length ? (
            <>
              {myAds.map((data) => {
                return (
                  <MyAdsCard
                    data={data}
                    key={data.post_id}
                    setPost={setPost}
                    setShowDeletePopup={setShowDeletePopup}
                    setShowStatusPopup={setShowStatusPopup}
                    showStatusPopup={showStatusPopup}
                    toggle={toggle}
                  />
                );
              })}
              <div className="mt-10">
                {totalPage > 5 ? (
                  <Pagination
                    setPage={setPage}
                    page={page}
                    totalData={
                      toggle === "Active"
                        ? data.active_ads
                        : toggle === "Inactive"
                        ? data.inactive_ads
                        : toggle === "Featured"
                        ? data.featured_ads
                        : toggle === "Completed"
                        ? data.completed_ads
                        : data.all_ads
                    }
                    totalPage={totalPage}
                  />
                ) : (
                  <SinglePagnination
                    setPage={setPage}
                    page={page}
                    totalData={
                      toggle === "Active"
                        ? data.active_ads
                        : toggle === "Inactive"
                        ? data.inactive_ads
                        : toggle === "Featured"
                        ? data.featured_ads
                        : toggle === "Completed"
                        ? data.completed_ads
                        : data.all_ads
                    }
                    totalPage={totalPage}
                  />
                )}
              </div>
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

export default MyAds;
