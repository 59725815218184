export const PROFILE_LOADING = "PROFILE_LOADING";
export const GET_PROFILE = "GET_PROFILE";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAIL = "UPLOAD_IMAGE_FAIL";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";
export const VERIFY_MOBILE_SUCCESS = "VERIFY_MOBILE_SUCCESS";
export const VERIFY_MOBILE_FAIL = "VERIFY_MOBILE_FAIL";
export const VERIFY_CODE_SUCCESS = "VERIFY_CODE_SUCCESS";
export const VERIFY_CODE_FAIL = "VERIFY_CODE_FAIL";
export const REMOVE_PROFILE_DATA = "REMOVE_PROFILE_DATA";
