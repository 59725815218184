import React from "react";
import {
  Breadcrum,
  Button,
  Categories,
  FormikControl,
  Head,
  Loading,
  LocationPopup,
  Newsletter,
} from "../../Component";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { scrollToTop } from "../../Component/NavigateTop";
import { fetchGetAllCategories } from "../../Redux/Category/action";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  fetchSendContact,
  removeContactData,
} from "../../Redux/Contact/action";
import Cookies from "js-cookie";

const Contact = () => {
  const [showCategories, setShowCategories] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [showLocation, setShowLocation] = useState(false);

  const data = localStorage.getItem("search-location");
  const parsedLocationData = JSON.parse(data);

  const [latitude, setLatitude] = useState(
    parsedLocationData ? parsedLocationData.latitude : ""
  );
  const [longitude, setLongitude] = useState(
    parsedLocationData ? parsedLocationData.longitude : ""
  );
  const [location, setLocation] = useState(
    parsedLocationData ? parsedLocationData.location : ""
  );
  const [keyword, setKeyword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("token");

  const loading = useSelector(
    (state) => state.categories.isloading,
    shallowEqual
  );

  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  const profileLoading = useSelector(
    (state) => state.profile.isloading,
    shallowEqual
  );

  const message = useSelector((state) => state.contact.message, shallowEqual);
  const errMessage = useSelector((state) => state.contact.errMsg, shallowEqual);

  const handleClickSearch = () => {
    if (latitude && longitude) {
      const filter = [
        {
          label: "latitudes[]",
          value: latitude,
        },
        {
          label: "longitudes[]",
          value: longitude,
        },
        {
          label: "location",
          value: location,
        },
      ];
      localStorage.setItem("search-data", JSON.stringify(filter));
      navigate(`/search-result?genre=all&type=offering&key=${keyword}`);
    } else {
      navigate(`/search-result?genre=all&type=offering&key=${keyword}`);
    }
  };

  const initialValues = {
    first_name: profile ? profile.first_name : "",
    last_name: profile ? profile.last_name : "",
    user_email: profile ? profile.user_email : "",
    msg: "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Required*"),
    last_name: Yup.string().required("Required*"),
    user_email: Yup.string().required("Required*"),
    msg: Yup.string().required("Required*"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    scrollToTop();
    const body = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.user_email,
      msg: values.msg,
    };
    setInProgress(true);
    dispatch(fetchSendContact(body));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm({ value: "" });
    }, 1000);
  };

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeContactData());
    message && setInProgress(false);
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && dispatch(removeContactData());
    errMessage && setInProgress(false);
  }, [errMessage]);

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetAllCategories());
  }, []);

  if (loading || (token && profileLoading) || inProgress) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pt-16 md:pt-24 relative">
      {showCategories || showLocation ? (
        <div
          className="absolute w-full h-full bg-black opacity-10 z-30"
          onClick={() => {
            setShowCategories(false);
            setShowLocation(false);
          }}
        ></div>
      ) : null}

      {showLocation && (
        <div className="w-full absolute top-28">
          <LocationPopup
            setShowLocation={setShowLocation}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            setLocation={setLocation}
            location={location}
          />
        </div>
      )}

      <Head
        color="#2E8B4A"
        setShowCategories={setShowCategories}
        showLocation={showLocation}
        setShowLocation={setShowLocation}
        location={location}
        setKeyword={setKeyword}
        handleClickSearch={handleClickSearch}
      />

      <hr className="w-full mt-5" />
      <div className="w-full bg-slate-100 py-10">
        <div className="w-11/12 xl:w-3/4 mx-auto">
          <Categories showCategories={showCategories} />
          <Breadcrum
            title="Home"
            subTitle="Classisupplier Request"
            subTitle2="Contact Us"
            link={() => navigate("/classisupplier-request")}
          />

          <h6 className="font-semibold text-2xl mt-8">Contact Us</h6>

          <div className="w-full mt-12 grid grid-cols-12 gap-x-5 h-full">
            <div className="col-span-12 md:col-span-8 w-full h-full border rounded-xl bg-white p-7">
              <h6 className="font-semibold text-xl">Company Details</h6>
              <div className="mt-8">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  validateOnMount
                >
                  {(formik) => (
                    <Form>
                      <div className="w-full flex items-center gap-x-5 flex-wrap lg:flex-nowrap">
                        <div className="w-full">
                          <FormikControl
                            label="First Name"
                            control="input"
                            name="first_name"
                            placeholder="Your first name"
                          />
                        </div>

                        <div className="w-full">
                          <FormikControl
                            label="Last Name"
                            control="input"
                            name="last_name"
                            placeholder="Your last name"
                          />
                        </div>
                      </div>

                      <div className="w-full mt-5">
                        <FormikControl
                          label="Email"
                          control="input"
                          name="user_email"
                          placeholder="Your email address"
                        />
                      </div>

                      <div className="w-full mt-5">
                        <FormikControl
                          label="Personalized Message"
                          name="msg"
                          control="textarea"
                          placeholder="Tell us a bit about your business"
                        />
                      </div>

                      <Button
                        value="Submit"
                        className="w-full bg-primary text-white mt-7 rounded-xl py-3"
                      />
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="mt-10 md:mt-0 col-start-1 md:col-start-9 col-end-13 w-full h-fit border rounded-xl bg-white">
              <h6 className="font-semibold text-xl px-7 py-5">Need Help?</h6>
              <hr />
              <div className="w-full p-7">
                <Button
                  value="How It Works"
                  handleClick={() => navigate("/how-it-works")}
                  className="w-full border border-primary md:text-sm lg:text-base text-base text-primary mt-5 py-3 rounded-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Newsletter
        color="#2E8B4A"
        bgColor="rgb(46 139 74 / var(--tw-bg-opacity))"
      />
    </div>
  );
};

export default Contact;
