import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Button } from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { removeCategoryData } from "../../Redux/Category/action";

const FormHeader = ({
  firstCat,
  secCat,
  thriCat,
  frthCat,
  setToggle,
  setSecCat,
  setThriCat,
  setFrthCat,
}) => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const formId = useSelector((state) => state.myAds.formId, shallowEqual);
  const title = useSelector((state) => state.myAds.title, shallowEqual);
  return (
    <div className="w-full p-5 border bg-white mt-10 rounded-xl">
      <h6 className="font-semibold text-xl">
        Form Id: <span className="text-primary">#{formId}</span>
      </h6>

      <div className="w-full items-center gap-x-3 flex text-sm mt-8 flex-wrap">
        {id ? (
          title.map((val, i) => {
            return (
              <div key={i} className="flex items-center gap-x-3">
                <p className="text-blue-500 cursor-pointer">{val}</p>
                <MdKeyboardArrowRight className="text-gray-400" />
              </div>
            );
          })
        ) : (
          <>
            <p className="text-blue-500 cursor-pointer">{firstCat.name}</p>
            <MdKeyboardArrowRight className="text-gray-400" />
            <p className="text-blue-500 cursor-pointer">{secCat.name}</p>
            {thriCat.name ? (
              <>
                <MdKeyboardArrowRight className="text-gray-400" />
                <p
                  className={`${
                    frthCat.name ? "text-blue-500" : "text-gray-400"
                  } font-medium`}
                >
                  {thriCat.name}
                </p>
              </>
            ) : null}

            {frthCat.name ? (
              <>
                <MdKeyboardArrowRight className="text-gray-400" />
                <p className="font-medium text-gray-400">{frthCat.name}</p>
              </>
            ) : null}
          </>
        )}

        {id ? null : (
          <Button
            value="Change"
            handleClick={() => {
              setToggle({
                category: true,
                detail: false,
                form: false,
              });
              dispatch(removeCategoryData());
              setSecCat({
                id: "",
                name: "",
              });
              setThriCat({
                id: "",
                name: "",
              });
              setFrthCat({
                id: "",
                name: "",
              });
            }}
            className="w-fit py-2 px-5 text-xs text-white bg-primary rounded-full"
          />
        )}
      </div>
    </div>
  );
};

export default FormHeader;
