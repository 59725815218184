import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const logoLoading = () => ({
  type: ActionTypes.LOGO_LOADING,
});

export const removeLogoData = () => ({
  type: ActionTypes.REMOVE_LOGO_DATA,
});

export const fetchUploadLogo = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/upload_logo`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.UPLOAD_LOGO_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.UPLOAD_LOGO_FAIL,
        payload: e?.response?.data,
      });
    });
};
