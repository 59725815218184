import axios from "axios";
import { BaseUrl2 } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const removeContactData = () => ({
  type: ActionTypes.REMOVE_CONTACT_DATA,
});

export const fetchSendContact = (body) => async (dispatch) => {
  await axios
    .post(`${BaseUrl2}/deal/add_contact`, body)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SEND_CONTACT_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.SEND_CONTACT_FAIL,
        payload: e?.response.data,
      });
    });
};
