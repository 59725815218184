import React, { useEffect } from "react";
import { Button, FormikControl, Loading } from "../../Component";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Cookies from "js-cookie";
import {
  fetchAgentRequest,
  fetchGetAgentRequestPortal,
  removeAgentRequestData,
} from "../../Redux/AgentRequest/action";
import { FaMoneyBills } from "react-icons/fa6";
import { scrollToTop } from "../../Component/NavigateTop";
import TextError from "../../Component/Formik/Error";
import { fetchGetUserProfile } from "../../Redux/Profile/action";

const AgentRequest = () => {
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  const profileLoading = useSelector(
    (state) => state.profile.isloading,
    shallowEqual
  );
  const portal = useSelector(
    (state) => state.agentRequest.portal,
    shallowEqual
  );
  const message = useSelector(
    (state) => state.agentRequest.message,
    shallowEqual
  );
  const errMessage = useSelector(
    (state) => state.agentRequest.errMsg,
    shallowEqual
  );

  //   initial values
  const initialValues = {
    company_abn: "",
    company_name: "",
    module_id: "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    company_abn: Yup.string().required("Required*"),
    company_name: Yup.string().required("Required*"),
    module_id: Yup.string().required("Required*"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    const body = {
      company_abn: values.company_abn,
      company_name: values.company_name,
      module_id: values.module_id,
    };
    dispatch(fetchAgentRequest(body, token));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm({ values: "" });
    }, 1000);
  };

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetAgentRequestPortal(token));
    dispatch(fetchGetUserProfile(token));
  }, []);

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeAgentRequestData());
    message && fetchGetAgentRequestPortal(token);
    message && dispatch(fetchGetUserProfile(token));
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && dispatch(removeAgentRequestData());
    errMessage && fetchGetAgentRequestPortal(token);
  }, [errMessage]);

  if (profileLoading) {
    return (
      <div className="w-full h-full">
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-full h-full mt-2 relative">
      <div className="w-full flex items-center gap-x-3">
        <h6 className="text-2xl font-semibold">Agent Request</h6>
        <div className="w-fit py-1 px-3 flex items-center gap-x-3 border rounded-full bg-white">
          <FaMoneyBills className="text-xl text-primary" />
          <p className="font-semibold">{profile.classi_credit} Coins</p>
        </div>
      </div>

      {profile.company_name && profile.is_agency === false ? (
        <p className="mt-10 text-xl font-medium">
          Your request has been submitted!
        </p>
      ) : profile.company_name && profile.is_agency === true ? (
        <div className="mt-10">
          <p>Your Request has been approved</p>
        </div>
      ) : (
        <div className="mt-10 w-full lg:w-3/4 mx-auto border bg-white rounded-xl p-6">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount
          >
            {(formik) => (
              <Form>
                <div className="w-full">
                  <FormikControl
                    label="Company Name"
                    control="input"
                    name="company_name"
                    placeholder="Your Company Name"
                  />
                </div>
                <div className="w-full  mt-5">
                  <FormikControl
                    label="ABN"
                    control="input"
                    name="company_abn"
                    placeholder="Your ABN"
                  />
                </div>

                <div className="text-sm w-full mt-5">
                  <label
                    htmlFor="module_id"
                    className="pb-2 font-medium flex text-textPrimary text-sm items-center justify-between"
                  >
                    Choose Portal{" "}
                    <ErrorMessage name="module_id" component={TextError} />
                  </label>

                  <Field
                    as="select"
                    name="module_id"
                    className="w-full px-2 py-3 border rounded-lg outline-gray-300 text-secondary"
                  >
                    <option value="" selected disabled>
                      Select
                    </option>
                    {portal &&
                      portal.map((val) => {
                        return (
                          <option key={val.id} value={val.id}>
                            {val.module_name}
                          </option>
                        );
                      })}
                  </Field>
                </div>
                <div className="w-full mt-7">
                  <Button
                    value="Submit"
                    type="submit"
                    className="bg-primary py-3.5 w-full rounded-xl text-white"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default AgentRequest;
