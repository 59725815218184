import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const dashaboardLoading = () => ({
  type: ActionTypes.DASHBOARD_LOADING,
});

export const removeDashboardData = () => ({
  type: ActionTypes.REMOVE_DASHBOARD_DATA,
});

export const fetchGetDashboardData = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/total_postads`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_DASHBOARD_DATA,
        payload: data,
      });
    });
};

export const fetchGetMyActivity = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/myactivity`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_MY_ACTIVITY,
        payload: data,
      });
    });
};

export const fetchGetRecentlyApprovedAds = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/approve_ads`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_APPROVED_ADS,
        payload: data,
      });
    });
};

export const fetchGetTodo = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/todos`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_TODOS,
        payload: data,
      });
    });
};

export const fetchAddTodo = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/todos/add`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.ADD_TODO_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.ADD_TODO_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchChangeTodoStatus = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/todos/status`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.CHANGE_TODO_STATUS_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.CHANGE_TODO_STATUS_FAIL,
        payload: e?.response.data,
      });
    });
};

export const fetchDeleteTodo = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/todos/delete`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.DELETE_TODO_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.DELETE_TODO_FAIL,
        payload: e?.response?.data,
      });
    });
};
