import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { BsFillClipboardXFill } from "react-icons/bs";
import { CiDiscount1 } from "react-icons/ci";
import { MdKeyboardArrowRight } from "react-icons/md";
import { PiCrownFill, PiMedalFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

const DashboardData = () => {
  const navigate = useNavigate();

  const dashboardData = useSelector(
    (state) => state.dashboard.dashboard,
    shallowEqual
  );
  return (
    <div className="w-full grid lg:grid-cols-2 gap-x-5 mt-10 ">
      <div className="w-full flex gap-x-5 px-5 border bg-white py-6 rounded-xl">
        <div className="p-2 h-fit bg-gray-100 rounded-full">
          <PiCrownFill className="text-primary text-3xl" />
        </div>
        <div className="w-full">
          <h5 className="text-3xl font-semibold">{dashboardData.myads}</h5>
          <div className="w-full flex items-center justify-between mt-2">
            <p className="text-xs xl:text-sm text-gray-500">Total Ads Posted</p>
            <p
              className="text-xs xl:text-sm text-gray-500 flex items-center cursor-pointer hover:underline"
              onClick={() => navigate("/me/my-ads")}
            >
              View Details <MdKeyboardArrowRight />
            </p>
          </div>
        </div>
      </div>

      <div className="w-full flex gap-x-5 px-5 border bg-white py-6 rounded-xl mt-5 lg:mt-0">
        <div className="p-2 h-fit bg-gray-100 rounded-full">
          <PiMedalFill className="text-primary text-3xl" />
        </div>
        <div className="w-full">
          <h5 className="text-3xl font-semibold">{dashboardData.featured}</h5>
          <div className="w-full flex items-center justify-between mt-2">
            <p className="text-xs xl:text-sm text-gray-500">Featured Ads</p>
            <p
              className="text-xs xl:text-sm text-gray-500 flex items-center cursor-pointer hover:underline"
              onClick={() => navigate(`/me/my-ads?status=Featured`)}
            >
              View Details <MdKeyboardArrowRight />
            </p>
          </div>
        </div>
      </div>

      <div className="w-full flex gap-x-5 px-5 border bg-white py-6 rounded-xl mt-5">
        <div className="p-2 h-fit bg-gray-100 rounded-full">
          <BsFillClipboardXFill className="text-primary text-3xl" />
        </div>
        <div className="w-full">
          <h5 className="text-3xl font-semibold">{dashboardData.inactive}</h5>
          <div className="w-full flex items-center justify-between mt-2">
            <p className="text-xs xl:text-sm text-gray-500">Inactive Ads</p>
            <p
              className="text-xs xl:text-sm text-gray-500 flex items-center cursor-pointer hover:underline"
              onClick={() => navigate(`/me/my-ads?status=Inactive`)}
            >
              View Details <MdKeyboardArrowRight />
            </p>
          </div>
        </div>
      </div>

      <div className="w-full flex gap-x-5 px-5 border bg-white py-6 rounded-xl mt-5">
        <div className="p-2 h-fit bg-gray-100 rounded-full">
          <CiDiscount1 className="text-primary text-3xl" />
        </div>
        <div className="w-full">
          <h5 className="text-3xl font-semibold">{dashboardData.mymsg}</h5>
          <div className="w-full flex items-center justify-between mt-2">
            <p className="text-xs xl:text-sm text-gray-500">
              Offers / Messages
            </p>
            <p
              className="text-xs xl:text-sm text-gray-500 flex items-center cursor-pointer hover:underline"
              onClick={() => navigate(`/me/offers`)}
            >
              View Details <MdKeyboardArrowRight />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardData;
