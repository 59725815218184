import React, { useEffect, useState } from "react";
import { FaMoneyBills } from "react-icons/fa6";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { scrollToTop } from "../../Component/NavigateTop";
import Header from "./Header";
import InviteFriends from "./InviteFriends";
import Cookies from "js-cookie";
import {
  fetchGetInviteLInks,
  removeReferralData,
} from "../../Redux/Referral/action";
import { Loading } from "../../Component";
import ReferralDashboard from "./ReferralDashboard";

const Referral = () => {
  const [toggle, setToggle] = useState(true);
  const [showShare, setShowShare] = useState(false);
  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const loading = useSelector(
    (state) => state.referral.isloading,
    shallowEqual
  );
  const profile = useSelector((state) => state.profile.profile, shallowEqual);

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetInviteLInks(token));
    return () => {
      dispatch(removeReferralData());
    };
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-full h-full relative">
      {showShare && (
        <div
          className="w-full absolute top-0 h-full bg-black opacity-10 z-30"
          onClick={() => setShowShare(false)}
        ></div>
      )}
      <div className="w-full flex items-center gap-x-3 flex-wrap">
        <h6 className="text-2xl font-semibold w-fit mt-2">
          Referral Management
        </h6>
        <div className="w-fit py-1 px-3 flex items-center gap-x-3 border rounded-full bg-white mt-2">
          <FaMoneyBills className="text-xl text-primary" />
          <p className="font-semibold">{profile.classi_credit} Coins</p>
        </div>
      </div>

      <Header toggle={toggle} setToggle={setToggle} />

      <div className="mt-8 w-ull bg-primary bg-opacity-20 p-5 border-l-4 border-l-primary">
        <h5 className="font-semibold tet-xl">
          Earn <span className="text-primary"> 20 Classi Coins </span> Each
        </h5>
        <p className="text-sm mt-2">
          Share the classibazaar invitation to your friend and get 100 Classi
          credit on sign up of 2 friends.{" "}
        </p>
      </div>
      {toggle ? (
        <ReferralDashboard />
      ) : (
        <InviteFriends setShowShare={setShowShare} showShare={showShare} />
      )}
    </div>
  );
};

export default Referral;
