import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  totalData: "",
  payment: [],
  message: "",
  errMsg: "",
};

export const Payment = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PAYMENT_HISTORY:
      return {
        ...state,
        isloading: false,
        success: true,
        payment: action.payload.payments,
        totalData: action.payload.total_number,
      };

    case ActionTypes.STRIPE_PAYMENT_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.data.message,
      };

    case ActionTypes.STRIPE_PAYMENT_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.CLASSI_CREDIT_PAYMENT_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.data.message,
      };

    case ActionTypes.CLASSI_CREDIT_PAYMENT_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.errors,
      };

    case ActionTypes.REMOVE_PAYMENT_DATA:
      return {
        isloading: true,
        success: false,
        payment: [],
        message: "",
        errMsg: "",
      };

    default:
      return state;
  }
};
