import React from "react";

const Header = ({ setToggle, toggle }) => {
  return (
    <div className="w-full flex items-center gap-x-5 mt-5 border-b">
      <h5
        className={`${
          toggle ? "border-b-2 border-b-primary" : "text-gray-500"
        } px-5 py-3 cursor-pointer`}
        onClick={() => {
          setToggle(true);
        }}
      >
        Referral Dashboard
      </h5>

      <h5
        className={`${
          !toggle ? "border-b-2 border-b-primary" : "text-gray-500"
        } px-5 py-3 cursor-pointer`}
        onClick={() => {
          setToggle(false);
        }}
      >
        Invite Friends
      </h5>
    </div>
  );
};

export default Header;
