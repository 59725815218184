import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  message: "",
  errMsg: "",
  countries: [],
  cities: [],
  categories: [],
};

export const Request = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SEND_API_REQUEST_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: "Request sent successfully",
      };

    case ActionTypes.SEND_API_REQUEST_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.GET_CLASSISUPPLIER_REQUEST:
      return {
        ...state,
        isloading: false,
        success: false,
        categories: action.payload.categories,
        cities: action.payload.cities,
        countries: action.payload.countries,
      };

    case ActionTypes.SEND_CLASSISUPPLIER_REQUEST_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: "Request sent successfully",
      };

    case ActionTypes.SEND_CLASSISUPPLIER_REQUEST_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.REMOVE_REQUEST_DATA:
      return {
        isloading: true,
        success: false,
        message: "",
        errMsg: "",
        countries: [],
        cities: [],
        categories: [],
      };

    default:
      return state;
  }
};
