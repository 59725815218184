import React from "react";
import {
  Loading,
  OffersCard,
  Pagination,
  SinglePagnination,
} from "../../Component";
import {
  fetchGetAllOfferMessages,
  removeInboxData,
} from "../../Redux/Message/action";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

const MessageSent = () => {
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let pg = params.get("page");
  const [page, setPage] = useState(pg ? Number(pg) : 1);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("token");
  const totalData = useSelector(
    (state) => state.message.totalData,
    shallowEqual
  );

  const offerMessages = useSelector(
    (state) => state.message.offerMessages,
    shallowEqual
  );

  const loading = useSelector((state) => state.message.isloading, shallowEqual);

  const totalPage = Math.floor(totalData / 10);

  const handleClickDetails = (id) => {
    navigate(`/my-inbox?indx=${id}`);
  };

  useEffect(() => {
    dispatch(fetchGetAllOfferMessages("sent", page, token));
    return () => {
      dispatch(removeInboxData());
    };
  }, [page]);

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="w-full bg-white border mt-10 rounded-xl">
        <p className="font-medium p-3">Latest Offers/Messages</p>
        <hr className="w-full" />
        <div className="w-full h-full p-3">
          {offerMessages && offerMessages.length ? (
            <>
              <div className="mt-5 grid grid-cols-1 lg:grid-cols-2 gap-x-5">
                {offerMessages.map((val, i) => {
                  return (
                    <OffersCard
                      key={i}
                      val={val}
                      handleClickDetails={handleClickDetails}
                    />
                  );
                })}
              </div>
            </>
          ) : (
            <h2 className="text-xl text-center font-semibold">
              Sorry!! No Offer Sent
            </h2>
          )}
        </div>
      </div>
      {offerMessages && offerMessages.length ? (
        <div className="mt-10">
          {totalPage > 5 ? (
            <Pagination
              setPage={setPage}
              page={page}
              totalData={totalData}
              totalPage={totalPage}
            />
          ) : (
            <SinglePagnination
              setPage={setPage}
              page={page}
              totalData={totalData}
              totalPage={totalPage}
            />
          )}
        </div>
      ) : null}
    </>
  );
};

export default MessageSent;
