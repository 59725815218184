import React from "react";
import { CiSearch } from "react-icons/ci";

const Search = ({
  placeholder,
  onchangeSearchHandler,
  searchValue,
  category,
  slugs,
}) => {
  return (
    <div className="w-full border rounded-xl pl-3 gap-x-2 flex items-center">
      <input
        type="text"
        placeholder={placeholder}
        className="py-2 text-sm w-full outline-none px-3"
        onChange={(e) => onchangeSearchHandler(e)}
        value={searchValue}
      />
      <div
        className={`p-2 text-white rounded-r-xl cursor-pointer ${
          category === "cars" || slugs === "cars"
            ? "bg-carPrimary"
            : category === "motorcycles-scooters" ||
              slugs === "motorcycles-scooters"
            ? " bg-carPrimary"
            : category === "jobs" || slugs === "jobs"
            ? "bg-jobPrimary"
            : "bg-primary"
        }`}
      >
        <CiSearch size={27} />
      </div>
    </div>
  );
};

export default Search;
