import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button } from "../../Component";
import { IoMdClose } from "react-icons/io";
import Cookies from "js-cookie";
import {
  fetchDeletePost,
  fetchGetDeleteReason,
  fetchGetMyAds,
  removeMyAdsData,
} from "../../Redux/MyAds/action";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";

const DeletePost = ({ post, setPost, setShowDeletePopup, page, toggle }) => {
  const [reasons, setReasons] = useState("");
  const reason = useSelector((state) => state.myAds.reason, shallowEqual);
  const message = useSelector((state) => state.myAds.message, shallowEqual);
  const errMsg = useSelector((state) => state.myAds.errMsg, shallowEqual);
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const handleClickDelete = () => {
    const body = {
      postid: post.id,
      reason: reasons,
    };
    dispatch(fetchDeletePost(body, token));
  };

  useEffect(() => {
    message && toast.success(message);
    message &&
      setPost({
        id: "",
        name: "",
      });

    message && setShowDeletePopup(false);

    message && dispatch(removeMyAdsData());

    setTimeout(() => {
      message && dispatch(fetchGetMyAds(page, toggle, token));
      message && dispatch(fetchGetDeleteReason(token));
    }, 100);
  }, [message]);

  useEffect(() => {
    errMsg && toast.success(errMsg);
  }, [errMsg]);

  return (
    <div className="absolute top-10 border rounded-2xl z-40 bg-white shadow-2xl w-11/12 lg:w-3/4 left-[5%] lg:left-[10%] py-5">
      <div className="px-5">
        <div className="flex w-full items-center justify-between">
          <p className="font-medium">Why Are You Deleting ad Ad?</p>
          <div
            className="w-fit p-2 rounded-lg cursor-pointer hover:bg-gray-100"
            onClick={() => {
              setPost({
                id: "",
                name: "",
              });
              setShowDeletePopup(false);
            }}
          >
            <IoMdClose className="text-lg text-gray-600" />
          </div>
        </div>
        <p className="mt-2 text-base font-medium text-primary">
          Ad: <span>{post.name}</span>
        </p>
      </div>
      <hr className="w-full mt-5" />
      <div className="mt-5">
        {reason &&
          reason.map((val, i) => {
            return (
              <div
                key={i}
                className="px-5 mt-2 text-base flex items-center gap-x-2"
              >
                <input
                  type="radio"
                  name="delete"
                  value={val}
                  onChange={(e) => setReasons(e.target.value)}
                />
                <p className="text-sm md:text-base">{val}</p>
              </div>
            );
          })}
      </div>
      <hr className="w-full mt-5" />
      <div className="flex items-center gap-x-5 justify-end px-5 mt-5">
        <Button
          value="Delete"
          handleClick={handleClickDelete}
          className="w-fit border rounded-lg py-2 px-7 text-sm bg-red-500 text-white"
        />
        <Button
          value="Cancel"
          handleClick={() => {
            setPost({
              id: "",
              name: "",
            });
            setShowDeletePopup(false);
          }}
          className="w-fit border rounded-lg py-2 px-7 text-sm"
        />
      </div>
    </div>
  );
};

export default DeletePost;
