import React from "react";
import { ErrorMessage, Field } from "formik";
import TextError from "../Formik/Error";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const PasswordField = ({
  label,
  placeholder,
  name,
  setShowPassword,
  showPassword,
  ...rest
}) => {
  return (
    <div className="text-sm">
      <label
        htmlFor={name}
        className="pb-2 font-medium flex text-textPrimary text-sm items-center justify-between"
      >
        {label} <ErrorMessage name={name} component={TextError} />
      </label>

      <div className="flex justify-between items-center relative">
        <Field
          id={name}
          name={name}
          {...rest}
          className="border border-gray-300 w-full text-sm p-3 rounded-lg outline-gray-300"
          placeholder={placeholder}
        />
        <div
          className="absolute right-4 opacity-40"
          onClick={(e) => setShowPassword(!showPassword)}
        >
          {showPassword ? (
            <AiFillEyeInvisible className="text-textSecondary" size={20} />
          ) : (
            <AiFillEye className="text-textSecondary" size={20} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PasswordField;
