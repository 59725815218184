import React, { useEffect, useState } from "react";
import {
  Breadcrum,
  Categories,
  Head,
  Loading,
  LocationPopup,
  Newsletter,
} from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { scrollToTop } from "../../Component/NavigateTop";
import { fetchGetAllCategories } from "../../Redux/Category/action";
import { useNavigate } from "react-router-dom";

const datas = [
  {
    id: 1,
    name: "The Daily Deal",
    detail:
      "Every day, our selected merchants will upload there deeply discounted deal in the local area of business.. Sign up to get our daily email.",
  },

  {
    id: 2,
    name: "Click To Buy",
    detail:
      "Every day, our selected merchants will upload there deeply discounted deal in the local area of business.. Sign up to get our daily email.",
  },

  {
    id: 3,
    name: "Share for Free Deal",
    detail:
      "Every day, our selected merchants will upload there deeply discounted deal in the local area of business.. Sign up to get our daily email.",
  },
];

const HowItWorks = () => {
  const [showCategories, setShowCategories] = useState(false);
  const [showLocation, setShowLocation] = useState(false);

  const data = localStorage.getItem("search-location");
  const parsedLocationData = JSON.parse(data);

  const [latitude, setLatitude] = useState(
    parsedLocationData ? parsedLocationData.latitude : ""
  );
  const [longitude, setLongitude] = useState(
    parsedLocationData ? parsedLocationData.longitude : ""
  );
  const [location, setLocation] = useState(
    parsedLocationData ? parsedLocationData.location : ""
  );
  const [keyword, setKeyword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector(
    (state) => state.categories.isloading,
    shallowEqual
  );

  const handleClickSearch = () => {
    if (latitude && longitude) {
      const filter = [
        {
          label: "latitudes[]",
          value: latitude,
        },
        {
          label: "longitudes[]",
          value: longitude,
        },
        {
          label: "location",
          value: location,
        },
      ];
      localStorage.setItem("search-data", JSON.stringify(filter));
      navigate(`/search-result?genre=all&type=offering&key=${keyword}`);
    } else {
      navigate(`/search-result?genre=all&type=offering&key=${keyword}`);
    }
  };

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetAllCategories());
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pt-16 md:pt-24 relative">
      {showCategories || showLocation ? (
        <div
          className="absolute w-full h-full bg-black opacity-10 z-30"
          onClick={() => {
            setShowCategories(false);
            setShowLocation(false);
          }}
        ></div>
      ) : null}

      {showLocation && (
        <div className="w-full absolute top-28">
          <LocationPopup
            setShowLocation={setShowLocation}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            setLocation={setLocation}
            location={location}
          />
        </div>
      )}
      <Head
        color="#2E8B4A"
        setShowCategories={setShowCategories}
        showLocation={showLocation}
        setShowLocation={setShowLocation}
        location={location}
        setKeyword={setKeyword}
        handleClickSearch={handleClickSearch}
      />

      <hr className="w-full mt-5" />

      <div className="w-full bg-slate-100 py-10">
        <div className="w-11/12 xl:w-3/4 mx-auto">
          <Categories showCategories={showCategories} />
          <Breadcrum
            title="Home"
            subTitle="Classisupplier Request"
            subTitle2="How It Works"
            link={() => navigate("/classisupplier-request")}
          />

          <h6 className="font-semibold text-2xl mt-8">How It Works</h6>

          <div className="w-full mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5">
            {datas &&
              datas.map((itm) => {
                return (
                  <div
                    className="w-full border rounded-xl bg-white p-5 relative mt-10"
                    key={itm.id}
                  >
                    <div className="absolute w-10 h-10 rounded-full border border-primary text-primary bg-white -top-5 left-[45%] right-1/2 text-center flex items-center justify-center">
                      {itm.id}
                    </div>

                    <h6 className="text-lg font-semibold text-center mt-5">
                      {itm.name}
                    </h6>
                    <p className="text-gray-600 leading-7 mt-4 text-center text-sm">
                      {itm.detail}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <Newsletter
        color="#2E8B4A"
        bgColor="rgb(46 139 74 / var(--tw-bg-opacity))"
      />
    </div>
  );
};

export default HowItWorks;
