import React from "react";
import { useEffect } from "react";
import { FaMoneyBills } from "react-icons/fa6";
import { shallowEqual, useSelector } from "react-redux";
import { scrollToTop } from "../../Component/NavigateTop";
import { Form, Formik } from "formik";
import { Button, FormikControl } from "../../Component";

const Coupon = () => {
  const profile = useSelector((state) => state.profile.profile, shallowEqual);

  const initialValues = {
    code: "",
  };

  const onSubmit = (values, onSubmitProps) => {
    console.log("values", values);
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="w-full h-full mt-2 relative">
      <div className="w-full flex items-center gap-x-3">
        <h6 className="text-2xl font-semibold">Coupon Code</h6>
        <div className="w-fit py-1 px-3 flex items-center gap-x-3 border rounded-full bg-white">
          <FaMoneyBills className="text-xl text-primary" />
          <p className="font-semibold">{profile.classi_credit} Coins</p>
        </div>
      </div>

      <div className="w-full mt-10 border rounded-xl bg-white">
        <p className="font-medium p-3">Add Coupon Code</p>
        <hr className="w-full" />
        <div className="mt-5">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnMount
          >
            {(formk) => (
              <Form>
                <div className="w-full p-3">
                  <FormikControl
                    label="Coupon Code"
                    control="input"
                    name="code"
                    placeholder="Add your code here"
                  />
                </div>
                <hr className="w-full" />
                <div className="w-full flex items-center justify-end p-3">
                  <Button
                    value="Submit Code"
                    className="w-fit bg-primary text-white px-7 py-3 text-sm mt-3 rounded-lg"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Coupon;
