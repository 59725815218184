import React from "react";
import Button from "../Button/Button";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  fetchSubscribeNewsletter,
  removeNewsletterData,
} from "../../Redux/Newsletter/action";
import { useState } from "react";

const Newsletter = ({ color, bgColor }) => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const message = useSelector(
    (state) => state.newsletter.message,
    shallowEqual
  );
  const errMsg = useSelector((state) => state.newsletter.errMsg, shallowEqual);

  const handleSubcribe = () => {
    const body = {
      email: email,
    };
    dispatch(fetchSubscribeNewsletter(body));
  };

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeNewsletterData());
    message && setEmail("");
  }, [message]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    errMsg && dispatch(removeNewsletterData());
  }, [errMsg]);

  return (
    <div
      className="py-16 w-full bg-opacity-10 flex items-center justify-center flex-col"
      style={{ backgroundColor: bgColor }}
    >
      <p className="font-medium tracking-wider">SUBSCRIBE FOR FREE</p>
      <h4 className="font-semibold text-2xl lg:text-3xl mt-5  flex flex-col">
        {" "}
        <span style={{ color: color }}>Subscribe to our newsletter</span>
        <span className="mt-2"> & get the latest updates</span>
      </h4>

      <div className="hidden w-11/12 lg:w-2/3 xl:w-1/2 2xl:w-2/5 mx-auto mt-10 bg-white p-2 md:flex items-center justify-between gap-x-5 border rounded-xl">
        <input
          type="text"
          className="w-8/12 p-3 outline-none"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email address"
          value={email}
        />
        <Button
          value="Subscribe Now"
          handleClick={handleSubcribe}
          className={` text-white rounded-xl py-3 px-5 w-fit`}
          style={{ background: color }}
        />
      </div>

      <div className="flex w-11/12 lg:w-2/3 xl:w-1/2 2xl:w-2/5 mx-auto mt-10 p-2 md:hidden items-center justify-between gap-x-5 flex-wrap">
        <input
          type="text"
          className="w-full p-3 py-4 outline-none border rounded-xl bg-white"
          placeholder="Enter your email address"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <Button
          value="Subscribe Now"
          handleClick={handleSubcribe}
          className={` text-white rounded-xl py-3 px-5 mt-5 w-fit`}
          style={{ background: color }}
        />
      </div>
      <p className="text-gray-600 text-sm mt-5">
        Your email is secure and won’t send you any spam
      </p>
    </div>
  );
};

export default Newsletter;
