import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const agentRequestLoading = () => ({
  type: ActionTypes.AGENT_REQUEST_LOADING,
});

export const removeAgentRequestData = () => ({
  type: ActionTypes.REMOVE_AGENT_REQUEST_DATA,
});

export const fetchAgentRequest = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/agency_request`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.AGENT_REQUEST_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.AGENT_REQUEST_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchGetAgentRequestPortal = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/agency_request`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_AGENT_PORTAL,
        payload: data,
      });
    });
};
