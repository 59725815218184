import React from "react";
import { about } from "../../Assets/Assest";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";

const ResgisterAds = ({ color }) => {
  const navigate = useNavigate();
  return (
    <div className="mt-16 border rounded-xl h-52 md:h-fit relative">
      <img
        src={about}
        alt=""
        className="w-full h-full object-fill md:object-contain rounded-xl"
      />

      <h5 className="absolute top-8 lg:top-16 px-5 md:px-10 text-base md:text-xl lg:text-3xl font-semibold w-3/5 leading-7 md:leading-10">
        <span style={{ color: color }}>Create an account </span> to post your
        ads for free
      </h5>
      <div className="px-5 md:px-10 absolute bottom-5 lg:bottom-12 2xl:bottom-20">
        <Button
          handleClick={() => navigate("/auth/sign-up")}
          value="Sign up for Free"
          className=" w-fit px-7 py-3 rounded-xl text-white"
          style={{ background: color }}
        />
      </div>
    </div>
  );
};

export default ResgisterAds;
