import axios from "axios";
import { BaseUrl2 } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const newsletterLoading = () => ({
  type: ActionTypes.NEWSLETTER_LOADING,
});

export const removeNewsletterData = () => ({
  type: ActionTypes.REMOVE_NEWSLETTER_DATA,
});

export const fetchSubscribeNewsletter = (body) => async (dispatch) => {
  await axios
    .post(`${BaseUrl2}/deal/newsletter`, body)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SUBSCRIBE_NEWSLETTER_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.SUBSCRIBE_NEWSLETTER_FAIL,
        payload: e?.response?.data,
      });
    });
};
