import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  message: "",
  errMsg: "",
};

export const Newsletter = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SUBSCRIBE_NEWSLETTER_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.SUBSCRIBE_NEWSLETTER_FAIL:
      return {
        ...state,
        isloading: false,
        success: true,
        errMsg: action.payload.message,
      };

    case ActionTypes.REMOVE_NEWSLETTER_DATA:
      return {
        isloading: true,
        success: false,
        message: "",
        errMsg: "",
      };

    default:
      return state;
  }
};
