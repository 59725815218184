import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: false,
  success: false,
  portal: [],
  message: "",
  errMsg: "",
};

export const AgentRequest = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.AGENT_REQUEST_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.AGENT_REQUEST_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.GET_AGENT_PORTAL:
      return {
        ...state,
        isloading: false,
        success: true,
        portal: action.payload.roles,
      };

    case ActionTypes.REMOVE_AGENT_REQUEST_DATA:
      return {
        isloading: false,
        success: false,
        message: "",
        errMsg: "",
      };

    default:
      return state;
  }
};
