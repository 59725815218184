import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const privacyLoading = () => ({
  type: ActionTypes.PRIVACY_SETTING_LOADING,
});

export const removePrivacyData = () => ({
  type: ActionTypes.REMOVE_PRIVACY_SETTING_DATA,
});

export const fetchGetPrivacySetting = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/privacy_setting`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_PRIVACY_SETTING,
        payload: data,
      });
    });
};

export const fetchUpdatePrivacySetting = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/privacy_setting`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.UPDATE_PRIVACY_SETTING_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.UPDATE_PRIVACY_SETTING_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchGetDeactivateReason = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/account_deactivate_reason`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_DEACTIVATE_REASON,
        payload: data,
      });
    });
};

export const fetchDeactivateAccount = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/deactivate_account`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.DEACTIVATE_ACCOUNT_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.DEACTIVATE_ACCOUNT_FAIL,
        payload: e?.response?.data,
      });
    });
};
