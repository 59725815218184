import axios from "axios";
import { BaseUrl2 } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const removeAboutus = () => ({
  type: ActionTypes.REMOVE_ABOUTUS,
});

export const fetchGetAboutus = () => async (dispatch) => {
  await axios.get(`${BaseUrl2}/deal/page?page_id=5`).then(({ data }) => {
    dispatch({
      type: ActionTypes.GET_ABOUTUS,
      payload: data,
    });
  });
};
