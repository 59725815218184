import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  profile: "",
  message: "",
  errMsg: "",
  verifyMsg: "",
  verifyErr: "",
  otpSucc: "",
  otpErr: "",
};

export const Profile = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PROFILE:
      return {
        ...state,
        isloading: false,
        success: true,
        profile: action.payload,
      };

    case ActionTypes.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.UPLOAD_IMAGE_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.UPDATE_PROFILE_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.UPDATE_PASSWORD_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.VERIFY_MOBILE_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        verifyMsg: action.payload.message,
      };

    case ActionTypes.VERIFY_MOBILE_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        verifyErr: action.payload.message,
      };

    case ActionTypes.VERIFY_CODE_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        otpSucc: action.payload.message,
      };

    case ActionTypes.VERIFY_CODE_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        otpErr: action.payload.message,
      };

    case ActionTypes.REMOVE_PROFILE_DATA:
      return {
        isloading: true,
        success: false,
        profile: "",
        verifyMsg: "",
        verifyErr: "",
        otpSucc: "",
        otpErr: "",
      };

    default:
      return state;
  }
};
