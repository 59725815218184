import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { classiBazarLogo } from "../../Assets/Assest";
import { Button } from "../../Component";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Component/NavigateTop";

const Category = ({
  firstCat,
  setFirstCat,
  setToggle,
  setSecCat,
  setThriCat,
  setFrthCat,
}) => {
  const navigate = useNavigate();

  const categories = useSelector(
    (state) => state.categories.categories,
    shallowEqual
  );

  return (
    <div className="w-full">
      <h6 className="font-semibold text-2xl">
        Choose <span className="text-primary">Category</span>
      </h6>
      <p className="text-base mt-2">
        Please choose one category to{" "}
        <span className="text-jobPrimary"> Get Started! </span>
      </p>
      <div className="w-full mt-6 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-x-5">
        {categories &&
          categories.map((val) => {
            return (
              <div
                key={val.cat_id}
                onClick={() => {
                  setFirstCat({ id: val.cat_id, name: val.cat_name });

                  if (firstCat.id !== val.cat_id) {
                    setSecCat({
                      id: "",
                      name: "",
                    });
                    setThriCat({
                      id: "",
                      name: "",
                    });
                    setFrthCat({
                      id: "",
                      name: "",
                    });
                  }
                }}
                className={`${
                  firstCat.id === val.cat_id
                    ? "border-primary bg-green-100"
                    : ""
                } border hover:border-primary rounded-xl transition-all ease-in-out duration-500 py-5 cursor-pointer w-full mt-6`}
              >
                <div className="w-full h-12 overflow-hidden">
                  {val.cat_image ? (
                    <img
                      src={val.cat_image}
                      alt=""
                      className="w-full h-full object-contain"
                    />
                  ) : (
                    <img
                      src={classiBazarLogo}
                      alt=""
                      className="w-full h-full object-contain"
                    />
                  )}
                </div>
                <p className="text-center text-xs font-medium mt-4">
                  {val.cat_name}
                </p>
              </div>
            );
          })}
      </div>

      <div className="w-full border mt-7 bg-white rounded-xl p-5 flex items-center justify-between">
        <div
          className="border rounded-xl py-3 px-7 w-52 flex items-center gap-x-2 justify-center cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <IoIosArrowRoundBack />
          <p>Back</p>
        </div>
        <Button
          value="Next"
          disable={firstCat.id ? false : true}
          handleClick={() => {
            setToggle({
              category: false,
              detail: true,
              form: false,
            });
            scrollToTop();
          }}
          className="border rounded-xl py-3 px-7 w-52 bg-primary text-white"
        />
      </div>
    </div>
  );
};

export default Category;
