import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  privacySetting: "",
  message: "",
  errMsg: "",
  deactivateMsg: "",
  deactivateErrMsg: "",
  reasons: [],
};

export const Privacy = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PRIVACY_SETTING:
      return {
        ...state,
        isloading: false,
        success: true,
        privacySetting: action.payload,
      };

    case ActionTypes.UPDATE_PRIVACY_SETTING_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.UPDATE_PRIVACY_SETTING_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.GET_DEACTIVATE_REASON:
      return {
        ...state,
        isloading: false,
        success: false,
        reasons: action.payload,
      };

    case ActionTypes.DEACTIVATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        deactivateMsg: action.payload.message,
      };

    case ActionTypes.DEACTIVATE_ACCOUNT_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        deactivateErrMsg: action.payload.message,
      };

    case ActionTypes.REMOVE_PRIVACY_SETTING_DATA:
      return {
        isloading: true,
        success: false,
        privacySetting: "",
        message: "",
        errMsg: "",
        reasons: [],
        deactivateMsg: "",
        deactivateErrMsg: "",
      };

    default:
      return state;
  }
};
