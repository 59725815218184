import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  wishlist: [],
  message: "",
  errMsg: "",
  totalData: "",
};

export const Wishlist = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_TO_WISHLIST_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.ADD_TO_WISHLIST_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.DELETE_WISHLIST_BY_POSTID_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.DELETE_WISHLIST_BY_POSTID_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.GET_MY_WISHLIST:
      return {
        ...state,
        isloading: false,
        success: true,
        wishlist: action.payload.wishlists,
        totalData: action.payload.total_number,
      };

    case ActionTypes.REMOVE_WISHLIST_DATA:
      return {
        isloading: true,
        success: false,
        message: "",
        errMsg: "",
        wishlist: [],
      };

    default:
      return state;
  }
};
