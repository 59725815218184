import Cookies from "js-cookie";
import React from "react";
import { NavLink, useParams } from "react-router-dom";

const menu = [
  {
    id: 1,
    name: "Dashboard",
    link: "/me",
  },

  {
    id: 2,
    name: "Profile Setting",
    link: "/me/profile-setting",
  },

  // {
  //   id: 3,
  //   name: "Coupon",
  //   link: "/me/coupon-offers",
  // },

  {
    id: 4,
    name: "My Ads",
    link: "/me/my-ads",
  },

  {
    id: 5,
    name: "Offer/Messages",
    link: "/me/offers",
  },

  // {
  //   id: 6,
  //   name: "Deals",
  //   link: "/me/deals",
  // },

  {
    id: 7,
    name: "Payments",
    link: "/me/payments",
  },

  {
    id: 13,
    name: "My Inspections",
    link: "/me/inspections",
  },

  {
    id: 8,
    name: "My Favourites",
    link: "/me/favourites",
  },

  {
    id: 100,
    name: "Saved Search",
    link: "/me/saved-search",
  },

  {
    id: 9,
    name: "Privacy Setting",
    link: "/me/privacy-setting",
  },

  {
    id: 10,
    name: "Referral Management",
    link: "/me/referral",
  },

  {
    id: 11,
    name: "Agent Request",
    link: "/me/agent-request",
  },
];

const AccountHeader = () => {
  const { status } = useParams();

  const handleClickLogout = () => {
    Cookies.remove("token");
    window.location.reload(false);
  };

  return (
    <div className="w-full h-full">
      <div className="border w-full py-1 rounded-lg flex flex-col gap-y-2 bg-white">
        {menu.map((val) => {
          return (
            <NavLink
              key={val.id}
              end
              to={val.link}
              className={({ isActive, isPending }) =>
                isActive || (status === "active" && val.name === "My Ads")
                  ? ` text-primary text-sm py-3 px-5 font-medium border-l-4 border-l-primary ${
                      val?.name !== "Logout" ? "border-b" : ""
                    }`
                  : `text-sm py-3  px-5 hover:text-textPrimary ${
                      val?.name !== "Logout" ? "border-b" : ""
                    }`
              }
            >
              {val?.name}
            </NavLink>
          );
        })}

        <p
          className="px-5 py-3 text-sm cursor-pointer"
          onClick={handleClickLogout}
        >
          Logout
        </p>
      </div>
    </div>
  );
};

export default AccountHeader;
