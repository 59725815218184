import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const notificationLoading = () => ({
  type: ActionTypes.NOTIFICATION_LOADING,
});

export const removeNotificationData = () => ({
  type: ActionTypes.REMOVE_NOTIFICATIOn,
});

export const fetchGetNotificationList = (page, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/user_notifications?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_NOTIFICATION,
        payload: data,
      });
    });
};

export const fetchMakeNotificationSeen = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/seen_notifications`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.MAKE_NOTIFICATION_SEEN,
        payload: data,
      });
    });
};
