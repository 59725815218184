import React from "react";
import { ErrorMessage, Field } from "formik";
import TextError from "../Formik/Error";

const TextArea = ({ label, placeholder, name, ...rest }) => {
  return (
    <div className="text-sm w-full">
      <label
        htmlFor={name}
        className="font-medium flex text-sm items-center justify-between w-full pb-2"
      >
        {label}

        <ErrorMessage name={name} component={TextError} />
      </label>

      <Field
        id={name}
        as="textarea"
        name={name}
        {...rest}
        className="border border-gray-300 w-full p-3 h-40 text-sm rounded-lg outline-gray-300"
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextArea;
