import React from "react";
import { classiBazarLogo } from "../../Assets/Assest";
import Button from "../Button/Button";

const OffersCard = ({ val, handleClickDetails }) => {
  return (
    <div className="w-full bg-white border p-5 rounded-xl hover:shadow-md mt-5">
      <div className="w-full flex gap-x-5 lg:gap-x-3">
        <div className="w-28 h-20">
          {val.image ? (
            <img
              src={val.image}
              alt=""
              className="w-full h-full object-fill rounded-xl"
            />
          ) : (
            <img
              src={classiBazarLogo}
              alt=""
              className="w-full h-full object-fill rounded-xl"
            />
          )}
        </div>
        <div className="w-full overflow-hidden">
          <h5 className="font-medium text-sm md:text-base capitalize truncate">
            {val.title}
          </h5>
          <p className="text-textSecondary mt-2 text-xs md:text-sm capitalize truncate2">
            Replies: {val.reply_number}
          </p>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          handleClick={() => handleClickDetails(val.post_id)}
          value="View Details"
          className="border py-2 rounded-xl px-5 transition duration-1000 ease-in-out hover:bg-gray-100 text-textPrimary mt-4 md:py-3"
        />
      </div>
    </div>
  );
};

export default OffersCard;
