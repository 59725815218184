import React from "react";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";

const Signup = ({ color, bgColor }) => {
  const navigate = useNavigate();
  return (
    <div
      className=" bg-opacity-10 py-10 flex items-center justify-center gap-x-5 flex-wrap "
      style={{ backgroundColor: bgColor }}
    >
      <h5 className="text-sm md:text-base lg:text-xl font-semibold my-5">
        So what are you waiting for?{" "}
        <span style={{ color: color }}> Join us today for FREE! </span>
      </h5>
      <Button
        value="Sign up Now"
        handleClick={() => navigate("/auth/sign-up")}
        style={{ background: color }}
        className={`rounded-xl text-white py-3 px-7 w-fit my-5 text-sm md:text-base`}
      />
    </div>
  );
};

export default Signup;
