import Cookies from "js-cookie";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchGetAgentEnquiry,
  removeInboxData,
} from "../../Redux/Message/action";
import { Loading, Pagination, SinglePagnination } from "../../Component";
import { AiOutlineEye } from "react-icons/ai";

const headings = [
  {
    id: 1,
    name: "S.N",
  },

  {
    id: 2,
    name: "Enquiry For",
  },

  {
    id: 3,
    name: "Property Condition",
  },

  {
    id: 4,
    name: "Type",
  },

  {
    id: 5,
    name: "Location",
  },

  {
    id: 6,
    name: "Action",
  },
];
const AgentEnquiry = () => {
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let pg = params.get("page");
  const [page, setPage] = useState(pg ? Number(pg) : 1);

  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const navigate = useNavigate();

  const loading = useSelector((state) => state.message.isloading, shallowEqual);
  const totalData = useSelector(
    (state) => state.message.totalData,
    shallowEqual
  );

  const enquiry = useSelector(
    (state) => state.message.agentEnquiry,
    shallowEqual
  );

  const totalPage = Math.floor(totalData / 10);

  useEffect(() => {
    dispatch(fetchGetAgentEnquiry(page, token));
    return () => {
      dispatch(removeInboxData());
    };
  }, [page]);

  if (loading) {
    return (
      <div className="w-full">
        <Loading />
      </div>
    );
  }
  return (
    <div className="py-2 align-middle min-w-full mt-2">
      <div class="relative overflow-x-auto">
        <table className="table-auto w-full border rounded-md divide-y divide-gray-200">
          <thead className="bg-gray-50 w-full">
            <tr>
              {headings.map((title) => {
                return (
                  <th
                    key={title.id}
                    scope="col"
                    className="px-6 py-3 text-center text-sm  font-medium "
                  >
                    {title.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {enquiry && enquiry.length ? (
              enquiry.map((data) => (
                <tr key={data.id}>
                  {/* S.N */}
                  <td className="px-2 py-4 whitespace-nowrap">
                    <div className="text-xs text-gray-600 text-center">
                      {data.id}
                    </div>
                  </td>

                  <td className="px-2 py-4 whitespace-nowrap">
                    <div className="text-xs text-gray-600 text-center">
                      {data.enquiry_for}
                    </div>
                  </td>

                  <td className="px-2 py-4 whitespace-nowrap">
                    <div className="text-xs text-gray-600 text-center">
                      {data.property_condition}
                    </div>
                  </td>

                  <td className="px-2 py-4 whitespace-nowrap">
                    <div className="text-xs text-gray-600 text-center">
                      {data.property_type}
                    </div>
                  </td>

                  <td className="px-2 py-4 whitespace-nowrap">
                    <div className="text-xs text-gray-600 text-center w-52 truncate">
                      {data.location?.[0]}
                    </div>
                  </td>

                  <td className="px-2 py-4 whitespace-nowrap">
                    <div
                      className="text-base cursor-pointer hover:text-primary text-gray-600 text-center flex items-center justify-center"
                      onClick={() =>
                        navigate(
                          `/agent-enquiry/${data.id}?u=${data.user_id}${
                            data.approved === 1 ? `&i=${data.i_userid}` : ""
                          }`
                        )
                      }
                    >
                      <AiOutlineEye />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <span>No Records Found</span>
            )}
          </tbody>
        </table>
      </div>

      {enquiry && enquiry.length ? (
        <div className="mt-10">
          {totalPage > 5 ? (
            <Pagination
              setPage={setPage}
              page={page}
              totalData={totalData}
              totalPage={totalPage}
            />
          ) : (
            <SinglePagnination
              setPage={setPage}
              page={page}
              totalData={totalData}
              totalPage={totalPage}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default AgentEnquiry;
