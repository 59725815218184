import React from "react";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import Image from "../UploadImage/Image";
import Button from "../Button/Button";
import { fetchUploadImage } from "../../Redux/Profile/action";

const ImagePopup = ({ setImagePopupModal }) => {
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const initialValues = {
    image: "",
  };

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    const formData = new FormData();
    formData.append("files", values.image);

    dispatch(fetchUploadImage(formData, token));

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 5000);
  };

  return (
    <div className="absolute top-10 border rounded-2xl z-40 bg-white shadow-2xl w-11/12 lg:w-3/4 left-[5%] lg:left-[10%] pt-8 md:pt-8 pb-10 ">
      <div className="w-11/12 xl:w-3/4 mx-auto">
        <h4 className="font-bold text-xl md:text-2xl text-primary text-center mb-8">
          Change Profile{" "}
        </h4>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <Image
                onChange={(e) =>
                  formik.setFieldValue("image", e.target.files[0])
                }
                file={formik.values.image}
              />

              <div className="flex justify-between items-center gap-x-10 mt-8">
                <div
                  className="border w-full rounded-2xl text-primary cursor-pointer text-xs md:text-sm font-medium py-4 text-center "
                  style={{ background: "#EBF0FA" }}
                  onClick={() => setImagePopupModal(false)}
                >
                  <Button value="Cancel" />
                </div>

                <Button
                  value="Save Changes"
                  type="submit"
                  className="border w-full text-xs rounded-2xl md:text-sm bg-primary cursor-pointer text-white font-medium py-4 text-center"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ImagePopup;
