import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const removeFaqData = () => ({
  type: ActionTypes.REMOVE_FAQ_DATA,
});

export const fetchGetFaqs = () => async (dispatch) => {
  await axios.get(`${BaseUrl}/faqs`).then(({ data }) => {
    dispatch({
      type: ActionTypes.GET_FAQS,
      payload: data,
    });
  });
};
