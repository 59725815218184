import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  dashboard: "",
  approvedAds: "",
  todos: [],
  activity: [],
  views: "",
  message: "",
  errMsg: "",
};

export const Dashboard = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_DASHBOARD_DATA:
      return {
        ...state,
        isloading: false,
        success: true,
        dashboard: action.payload.invite_link,
      };

    case ActionTypes.GET_MY_ACTIVITY:
      return {
        ...state,
        isloading: false,
        success: true,
        activity: action.payload,
      };

    case ActionTypes.GET_APPROVED_ADS:
      return {
        ...state,
        isloading: false,
        success: true,
        approvedAds: action.payload,
      };

    case ActionTypes.GET_TODOS:
      return {
        ...state,
        isloading: false,
        success: true,
        todos: action.payload,
      };

    case ActionTypes.ADD_TODO_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.ADD_TODO_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.CHANGE_TODO_STATUS_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.CHANGE_TODO_STATUS_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.DELETE_TODO_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.DELETE_TODO_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.REMOVE_DASHBOARD_DATA:
      return {
        isloading: true,
        success: false,
        dashboard: "",
        approvedAds: "",
        todos: [],
        message: "",
        errMsg: "",
        activity: [],
      };

    default:
      return state;
  }
};
