import React from "react";
import { ErrorMessage, Field } from "formik";
import TextError from "../Formik/Error";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { FiCheck } from "react-icons/fi";

const InputField = ({
  label,
  checked,
  setShowOtpPopup,
  placeholder,
  name,
  ...rest
}) => {
  return (
    <div className="text-sm">
      <div className="w-full flex items-center gap-x-5 pb-2">
        <label
          htmlFor={name}
          className="font-medium flex text-sm items-center justify-between w-full"
        >
          {label}

          <ErrorMessage name={name} component={TextError} />
        </label>

        {checked === true ? (
          <h1 className="text-green-700 bg-green-100 rounded-full md:py-1 text-[0.5rem] md:text-xs w-fit px-3 md:px-4 flex items-center gap-x-1 md:gap-x-2">
            <FiCheck />
            <span>Verified </span>
          </h1>
        ) : checked === false ? (
          <h1
            className="text-red-700 cursor-pointer bg-red-100 rounded-full md:py-1 text-[0.5rem] md:text-xs min-w-max px-3 md:px-4 flex items-center gap-x-1 md:gap-x-2"
            onClick={() => {
              setShowOtpPopup(true);
            }}
          >
            <IoIosInformationCircleOutline />
            <span>Not Verified </span>
          </h1>
        ) : null}
      </div>

      <Field
        id={name}
        name={name}
        {...rest}
        className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
        placeholder={placeholder}
      />
    </div>
  );
};

export default InputField;
