import React from "react";
import { IoMdClose } from "react-icons/io";
import { NavLink } from "react-router-dom";

const menu = [
  {
    id: 1,
    name: "Dashboard",
    link: "/me",
  },

  {
    id: 2,
    name: "Profile Setting",
    link: "/me/profile-setting",
  },

  // {
  //   id: 3,
  //   name: "Coupon",
  //   link: "/me/coupon-offers",
  // },

  {
    id: 4,
    name: "My Ads",
    link: "/me/my-ads",
  },

  {
    id: 5,
    name: "Offer/Messages",
    link: "/me/offers",
  },

  // {
  //   id: 6,
  //   name: "Deals",
  //   link: "/me/deals",
  // },

  {
    id: 7,
    name: "Payments",
    link: "/me/payments",
  },

  {
    id: 13,
    name: "My Inspections",
    link: "/me/inspections",
  },

  {
    id: 8,
    name: "My Favourites",
    link: "/me/favourites",
  },

  {
    id: 100,
    name: "Saved Search",
    link: "/me/saved-search",
  },

  {
    id: 9,
    name: "Privacy Setting",
    link: "/me/privacy-setting",
  },

  {
    id: 10,
    name: "Referral Management",
    link: "/me/referral",
  },

  {
    id: 11,
    name: "Agent Request",
    link: "/me/agent-request",
  },

  {
    id: 12,
    name: "Logout",
    link: "/me/logout",
  },
];

const MobileAccountHeader = ({ mobileMenu, setMobileMenu }) => {
  return (
    <div
      className={` bg-white fixed left-0 top-16 pb-10 h-full w-2/3 sm:w-2/6 shadow-2xl backdrop-blur-2xl transform z-50 ease-in-out duration-500 overflow-y-auto  ${
        mobileMenu ? "-translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className="w-full flex items-center justify-between mt-5 px-3">
        <p className="font-semibold text-lg">Menu</p>
        <div
          className="w-fit p-3 rounded-md bg-gray-100 cursor-pointer"
          onClick={() => setMobileMenu(false)}
        >
          <IoMdClose className="text-red-700" />
        </div>
      </div>
      <hr className="w-full mt-5" />
      <div className="w-full h-full mt-5 px-3 py-5 flex flex-col gap-y-2 bg-white">
        {menu.map((val) => {
          return (
            <NavLink
              key={val.id}
              onClick={() => setMobileMenu(false)}
              end
              to={val.link}
              className={({ isActive, isPending }) =>
                isActive
                  ? " text-white rounded-xl text-base bg-primary py-3 px-5 font-medium"
                  : "text-sm py-3 text-textSecondary px-5 hover:text-textPrimary"
              }
            >
              {val.name}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default MobileAccountHeader;
