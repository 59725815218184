import React from "react";
import { bike, car, job, realestate } from "../../Assets/Assest";
import { Link } from "react-router-dom";

const category = [
  {
    id: 1,
    name: "Cars",
    img: car,
    link: "/cars",
  },
  {
    id: 2,
    name: "Jobs",
    img: job,
    link: "/jobs",
  },
  {
    id: 3,
    name: "Real Estate",
    img: realestate,
    link: "/real-estate",
  },
  {
    id: 4,
    name: "Bike",
    img: bike,
    link: "/bikes",
  },
];

const FeaturedCategory = () => {
  return (
    <div className="mt-6 w-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-x-8">
      {category.map((val) => {
        return (
          <div
            key={val.id}
            className="w-full mt-8 h-[26rem] rounded-xl overflow-hidden relative cursor-pointer ease-in-out transition-all duration-300 hover:scale-105"
          >
            <Link to={val.link}>
              <img
                src={val.img}
                alt="val.name"
                className="w-full h-full object-cover md:object-fill"
              />

              <p className="absolute bottom-8 text-center text-3xl text-white md:text-base flex items-center justify-center w-full">
                {val.name}
              </p>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default FeaturedCategory;
