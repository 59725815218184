import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { shallowEqual, useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import { useEffect } from "react";
import { useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

const TotalViews = () => {
  const [data, setData] = useState();

  const totalViews = useSelector((state) => state.myAds.views, shallowEqual);

  useEffect(() => {
    const data = Object.entries(totalViews).map(([key, value]) => ({
      key,
      value,
    }));
    setData(data);
  }, [totalViews]);

  const labels = data && data.map((item) => item.key);
  const values = data && data.map((item) => item.value);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Bar Chart",
        data: values,
        backgroundColor: "rgba(75,192,192,0.6)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className="w-full h-[26rem] overflow-auto border rounded-md bg-white relative">
      <div className="w-full sticky top-0">
        <div className="w-full flex items-center justify-between p-3  bg-white ">
          <h6 className="font-medium">Total Views</h6>
          {/* <p className="text-xs xl:text-sm text-primary flex items-center cursor-pointer hover:underline">
            View Details <MdKeyboardArrowRight className="text-lg" />
          </p> */}
        </div>
        <hr className="w-full" />
      </div>

      <div className="w-full h-fit flex items-center justify-center mt-20">
        <Bar options={options} data={chartData} />
      </div>
    </div>
  );
};

export default TotalViews;
