import React from "react";
import { FaMoneyBills } from "react-icons/fa6";
import { shallowEqual, useSelector } from "react-redux";

const SavedSearches = () => {
  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  return (
    <div className="w-full h-full mt-2 relative">
      <div className="w-full flex items-center gap-x-3">
        <h6 className="text-2xl font-semibold">Saved Searches</h6>
        <div className="w-fit py-1 px-3 flex items-center gap-x-3 border rounded-full bg-white">
          <FaMoneyBills className="text-xl text-primary" />
          <p className="font-semibold">{profile.classi_credit} Coins</p>
        </div>
      </div>
    </div>
  );
};

export default SavedSearches;
