import React, { useEffect, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetAllCategories,
  fetchGetSecondLevelCategories,
} from "../../Redux/Category/action";
import Loading from "../Loading/Loading";
import { useNavigate } from "react-router-dom";

const Categories = ({ showCategories, setShowCategories }) => {
  const [catId, setCatId] = useState("");
  const [catName, setCatName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector(
    (state) => state.categories.isloading,
    shallowEqual
  );

  const categories = useSelector(
    (state) => state.categories.categories,
    shallowEqual
  );

  const secCategories = useSelector(
    (state) => state.categories.secCategories,
    shallowEqual
  );

  const thirdCategories = useSelector(
    (state) => state.categories.thirdCategories,
    shallowEqual
  );

  const handleClickCategory = (id) => {
    dispatch(fetchGetSecondLevelCategories(id));
  };

  useEffect(() => {
    dispatch(fetchGetAllCategories());
  }, []);

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div
      className={`bg-white h-full fixed left-0 top-16 sm:top-20 md:top-24 min-w-[15%] shadow-2xl backdrop-blur-2xl transform z-50 ease-in-out duration-500 overflow-y-auto overflow-x-visible ${
        showCategories ? "-translate-x-0" : "-translate-x-full"
      }`}
    >
      <h6 className="w-full text-xl font-medium px-10 py-5">Our Categories</h6>
      <hr className="w-full" />

      <div className="w-full h-full flex gap-x-4">
        <div
          className={`w-full h-full md:w-fit md:px-5 ${
            secCategories && secCategories.length ? "border-r" : ""
          }`}
        >
          {categories &&
            categories.map((val, i) => {
              return (
                <div
                  className={`${
                    catId === val.cat_id ? "bg-primary bg-opacity-10" : ""
                  } w-full flex items-center justify-between mt-8 hover:bg-gray-100 px-2 md:px-5 py-2 cursor-pointer rounded-md`}
                  key={i}
                  onMouseEnter={() => {
                    handleClickCategory(val.cat_id);
                    setCatId(val.cat_id);
                    setCatName(val.slug);
                  }}
                  onClick={() => {
                    navigate(`/search-result?genre=${val.slug}&type=offering`);
                    setShowCategories(false);
                  }}
                >
                  <div className="flex items-center gap-x-2 md:gap-x-5">
                    <img
                      src={val.cat_image}
                      alt=""
                      className="w-7 h-7 md:w-10 md:h-10 object-contain"
                    />
                    <p className="text-sm md:text-base">{val.cat_name}</p>
                  </div>
                  <MdKeyboardArrowRight />
                </div>
              );
            })}
        </div>

        <div
          className={`w-full h-full md:w-fit px-3 ${
            thirdCategories && thirdCategories.length ? "border-r" : ""
          }`}
        >
          {secCategories && secCategories.length
            ? secCategories.map((val, i) => {
                return (
                  <div
                    className={` w-full flex items-center justify-between mt-8 hover:bg-gray-100 px-5 py-2 cursor-pointer rounded-md`}
                    key={i}
                    onClick={() => {
                      navigate(
                        `/search-result?slug=${catName}&genre=${val.slug}&type=offering`
                      );
                      setShowCategories(false);
                    }}
                  >
                    <div className="flex items-center gap-x-5">
                      <img
                        src={val.cat_image}
                        alt=""
                        className="w-7 h-7 object-contain"
                      />
                      <p>{val.cat_name}</p>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default Categories;
