import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { scrollToTop } from "../../Component/NavigateTop";
import { shallowEqual, useSelector } from "react-redux";

const Faq = () => {
  const [isOpen, setIsOpen] = useState({});
  const [show, setShow] = useState(false);

  const faq = useSelector((state) => state.faq.faq, shallowEqual);

  const handleOpen = (item) => {
    setIsOpen((prevState) => ({
      [item]: !prevState[item],
    }));

    if (show === item) {
      return setShow(null);
    }
    setShow(item);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="w-11/12 xl:w-3/4 mx-auto">
      {faq &&
        faq.map((data, i) => {
          return (
            <div
              key={data.faq_id}
              className=" cursor-pointer"
              onClick={() => {
                handleOpen(data.faq_id);
              }}
            >
              <div className="flex w-full items-center justify-between py-4">
                <h6 className="font-medium text-sm md:text-base">
                  {data.name}
                </h6>
                <div
                  className={`arrow transition ease-in-out duration-500 text-3xl md:text-4xl delay-100 ${
                    !isOpen[data.faq_id] ? "close" : " open"
                  }`}
                >
                  +
                </div>
              </div>
              <h5
                className={
                  show === data.faq_id
                    ? "w-full text-secondary text-xs md:text-sm pb-8 mx-auto ease-in-out transition-all duration-1000 text-justify"
                    : "hidden"
                }
              >
                {data.content}
              </h5>

              <hr className={i === 11 ? "hidden " : "block"} />
            </div>
          );
        })}
    </div>
  );
};

export default Faq;
