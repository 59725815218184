import React, { useMemo } from "react";
import {
  BlogsCard,
  Breadcrum,
  Categories,
  Head,
  Loading,
  LocationPopup,
  Newsletter,
  Pagination,
  Search,
  SinglePagnination,
} from "../../Component";
import { useState } from "react";
import { useEffect } from "react";
import { scrollToTop } from "../../Component/NavigateTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetBlogs, removeBlogsData } from "../../Redux/Blogs/action";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchGetAllCategories } from "../../Redux/Category/action";
import debounce from "lodash.debounce";

const Blogs = () => {
  const [showCategories, setShowCategories] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [category, setCategory] = useState("");

  const [showLocation, setShowLocation] = useState(false);

  const data = localStorage.getItem("search-location");
  const parsedLocationData = JSON.parse(data);

  const [latitude, setLatitude] = useState(
    parsedLocationData ? parsedLocationData.latitude : ""
  );
  const [longitude, setLongitude] = useState(
    parsedLocationData ? parsedLocationData.longitude : ""
  );
  const [locations, setLocations] = useState(
    parsedLocationData ? parsedLocationData.location : ""
  );
  const [key, setKey] = useState("");

  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let pg = params.get("page");

  const [page, setPage] = useState(pg ? Number(pg) : 1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => state.blogs.isloading, shallowEqual);
  const catLoading = useSelector(
    (state) => state.categories.isloading,
    shallowEqual
  );
  const categories = useSelector((state) => state.blogs.category, shallowEqual);
  const blogs = useSelector((state) => state.blogs.blogs, shallowEqual);
  const totalData = useSelector((state) => state.blogs.totalData, shallowEqual);

  const totalPage = Math.ceil(totalData / 8);

  const handleClickSearch = () => {
    if (latitude && longitude) {
      const filter = [
        {
          label: "latitudes[]",
          value: latitude,
        },
        {
          label: "longitudes[]",
          value: longitude,
        },
        {
          label: "location",
          value: locations,
        },
      ];
      localStorage.setItem("search-data", JSON.stringify(filter));
      navigate(`/search-result?genre=all&type=offering&key=${key}`);
    } else {
      navigate(`/search-result?genre=all&type=offering&key=${key}`);
    }
  };

  const onchangeSearchHandler = (e) => {
    setKeyword(e.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(onchangeSearchHandler, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetBlogs(keyword, category));
    dispatch(fetchGetAllCategories());
    return () => {
      dispatch(removeBlogsData());
    };
  }, [category, page]);

  useEffect(() => {
    dispatch(fetchGetBlogs(keyword, category));
  }, [keyword]);

  if (loading || catLoading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pt-16 md:pt-24 relative">
      {showCategories || showLocation ? (
        <div
          className="absolute w-full h-full bg-black opacity-10"
          onClick={() => {
            setShowCategories(false);
            setShowLocation(false);
          }}
        ></div>
      ) : null}

      {showLocation && (
        <LocationPopup
          setShowLocation={setShowLocation}
          setLatitude={setLatitude}
          setLongitude={setLongitude}
          setLocation={setLocations}
          location={locations}
        />
      )}

      <Categories showCategories={showCategories} />

      <Head
        setShowCategories={setShowCategories}
        color="#2E8B4A"
        showLocation={showLocation}
        setShowLocation={setShowLocation}
        location={locations}
        setKeyword={setKey}
        handleClickSearch={handleClickSearch}
      />
      <hr className="w-full mt-7" />

      <div className="w-11/12 xl:w-3/4 mx-auto mt-10">
        <Breadcrum title="Home" subTitle="Blog" />

        <div className="w-full grid grid-cols-12 gap-x-5 mt-10">
          <div className="w-full col-span-4 hidden md:block mt-5">
            <div className="border p-3 rounded-lg">
              <p className="font-medium mb-4">Search</p>
              <Search
                placeholder="Search Keyword"
                onchangeSearchHandler={debouncedResults}
              />
            </div>

            <div className="w-full border rounded-lg mt-5">
              <p className="p-3 w-full font-medium">Explore by Categories</p>
              <hr />
              {categories &&
                categories.map((val) => {
                  return (
                    <div
                      className={`hover:bg-gray-100 cursor-pointer p-3 ${
                        category === val.slug ? "bg-gray-200" : ""
                      }`}
                      onClick={() => setCategory(val.slug)}
                      key={val.id}
                    >
                      <p className="text-sm">{val.title}</p>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="w-full col-start-1 md:col-start-5 col-end-13">
            {blogs &&
              blogs.map((val) => {
                return <BlogsCard data={val} key={val.id} />;
              })}
            <div className="mt-10">
              {totalPage > 5 ? (
                <Pagination
                  setPage={setPage}
                  page={page}
                  totalData={totalData}
                  totalPage={totalPage}
                />
              ) : (
                <SinglePagnination
                  setPage={setPage}
                  page={page}
                  totalData={totalData}
                  totalPage={totalPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-10">
        <Newsletter
          color="#2E8B4A"
          bgColor="rgb(46 139 74 / var(--tw-bg-opacity))"
        />
      </div>
    </div>
  );
};

export default Blogs;
