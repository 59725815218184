export const DASHBOARD_LOADING = "DASHBOARD_LOADING";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_APPROVED_ADS = "GET_APPROVED_ADS";
export const GET_MY_ACTIVITY = "GET_MY_ACTIVITY";
export const GET_TODOS = "GET_TODOS";
export const ADD_TODO_SUCCESS = "ADD_TODO_SUCCESS";
export const ADD_TODO_FAIL = "ADD_TODO_FAIL";
export const CHANGE_TODO_STATUS_SUCCESS = "CHANGE_TODO_STATUS_SUCCESS";
export const CHANGE_TODO_STATUS_FAIL = "CHANGE_TODO_STATUS_FAIL";
export const DELETE_TODO_SUCCESS = "DELETE_TODO_SUCCESS";
export const DELETE_TODO_FAIL = "DELETE_TODO_FAIL";
export const REMOVE_DASHBOARD_DATA = "REMOVE_DASHBOARD_DATA";
