import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const wishlistLoading = () => ({
  type: ActionTypes.WISHLIST_LOADING,
});

export const removeWishlistData = () => ({
  type: ActionTypes.REMOVE_WISHLIST_DATA,
});

export const fetchAddtoWishlist = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/add_wishlist`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.ADD_TO_WISHLIST_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.ADD_TO_WISHLIST_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchRemoveWishlistByPostId =
  (body, token) => async (dispatch) => {
    await axios
      .post(`${BaseUrl}/delete_wishlist_withpostid`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.DELETE_WISHLIST_BY_POSTID_SUCCESS,
          payload: data,
        });
      })
      .catch((e) => {
        dispatch({
          type: ActionTypes.DELETE_WISHLIST_BY_POSTID_FAIL,
          payload: e.response.data,
        });
      });
  };

export const fetchGetMyWishlist = (page, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/wishlists_new?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_MY_WISHLIST,
        payload: data,
      });
    });
};
