import React, { useState } from "react";
import { classiBazarLogo } from "../../Assets/Assest";

const GridCategories = ({ handleClick, color, image, name }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const defaultStyle = {
    border: isHovered ? `1px solid ${color}` : "none",
  };
  return (
    <div
      className={`hover:border rounded-xl transition-all ease-in-out duration-500 py-5 cursor-pointer w-full mt-6`}
      style={defaultStyle}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleClick}
    >
      <div className="w-full h-14 overflow-hidden">
        {image ? (
          <img src={image} alt="" className="w-full h-full object-contain" />
        ) : (
          <img
            src={classiBazarLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        )}
      </div>
      <p className="text-center text-sm font-medium mt-4">{name}</p>
    </div>
  );
};

export default GridCategories;
