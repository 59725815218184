import React from "react";
import { Button } from "../../Component";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="py-16 md:py-24 w-11/12 md:w-2/3 lg:w-[45%] xl:w-[38%] 2xl:w-[30%] mx-auto">
      <div className="mt-20 w-full flex items-center justify-center flex-col">
        <h1 className="text-7xl 2xl:text-9xl text-center text-primary font-black">
          404
        </h1>
        <h4 className="text-center text-2xl 2xl:text-4xl font-semibold mt-3">
          Page Not Found
        </h4>
        <p className="text-base mt-8 text-center">
          We’re sorry, the page you requested could not be found. Please go back
          to homepage.
        </p>

        <Button
          value="Go to Home"
          handleClick={() => navigate("/")}
          className="w-fit rounded-xl text-sm bg-primary text-white px-7 py-3 mt-7"
        />
      </div>
    </div>
  );
};

export default PageNotFound;
