import Cookies from "js-cookie";
import React from "react";
import { useState } from "react";
import { FaMoneyBills } from "react-icons/fa6";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Loading, MyInspectionCard } from "../../Component";
import { useEffect } from "react";
import {
  fetchGetMyInspection,
  removeMyAdsData,
} from "../../Redux/MyAds/action";
import { removeAdsData } from "../../Redux/Ads/action";
import { toast } from "react-toastify";

const MyInspection = () => {
  const [toggle, setToggle] = useState({
    all: true,
    today: false,
    tomorrow: false,
    week: false,
    month: false,
  });

  const allInspection = useSelector(
    (state) => state.myAds.allInspection,
    shallowEqual
  );

  const [inspections, setInspection] = useState(allInspection);
  const todayInspection = useSelector(
    (state) => state.myAds.todayInspection,
    shallowEqual
  );
  const tomorrowInspection = useSelector(
    (state) => state.myAds.tomorrowInspection,
    shallowEqual
  );
  const weekInspection = useSelector(
    (state) => state.myAds.weekInspection,
    shallowEqual
  );
  const monthInspection = useSelector(
    (state) => state.myAds.monthInspection,
    shallowEqual
  );

  const profile = useSelector((state) => state.profile.profile, shallowEqual);

  const [all, setAll] = useState();
  const [today, setToday] = useState();
  const [tomm, setTomm] = useState();
  const [week, setWeek] = useState();
  const [month, setMonth] = useState();

  const dispatch = useDispatch();

  const token = Cookies.get("token");

  const loading = useSelector((state) => state.myAds.isloading, shallowEqual);

  const message = useSelector((state) => state.ads.message, shallowEqual);
  const errMsg = useSelector((state) => state.ads.errMsg, shallowEqual);

  useEffect(() => {
    setInspection(allInspection);
    setToggle({
      all: true,
      today: false,
      tomorrow: false,
      week: false,
      month: false,
    });
  }, [allInspection]);

  const handleClickAll = () => {
    setToggle({
      all: true,
      today: false,
      tomorrow: false,
      week: false,
      month: false,
    });

    setInspection(allInspection);
  };

  const handleClickToday = () => {
    setToggle({
      all: false,
      today: true,
      tomorrow: false,
      week: false,
      month: false,
    });

    setInspection(todayInspection);
  };

  const handleClickTomorrow = () => {
    setToggle({
      all: false,
      today: false,
      tomorrow: true,
      week: false,
      month: false,
    });

    setInspection(tomorrowInspection);
  };

  const handleClickWeek = () => {
    setToggle({
      all: false,
      today: false,
      tomorrow: false,
      week: true,
      month: false,
    });

    setInspection(weekInspection);
  };

  const handleClickMonth = () => {
    setToggle({
      all: false,
      today: false,
      tomorrow: false,
      week: false,
      month: true,
    });
    setInspection(monthInspection);
  };

  useEffect(() => {
    const allInspec = allInspection.map((val) => val?.inspection_times.length);
    setAll(
      allInspec.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      )
    );

    const todayInspec = todayInspection.map(
      (val) => val?.inspection_times.length
    );
    setToday(
      todayInspec.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      )
    );

    const tomInspec = tomorrowInspection.map(
      (val) => val?.inspection_times.length
    );
    setTomm(
      tomInspec.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      )
    );

    const weekInspec = weekInspection.map(
      (val) => val?.inspection_times.length
    );
    setWeek(
      weekInspec.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      )
    );

    const mnthInspec = monthInspection.map(
      (val) => val?.inspection_times.length
    );
    setMonth(
      mnthInspec.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      )
    );
  }, [
    allInspection,
    todayInspection,
    monthInspection,
    weekInspection,
    tomorrowInspection,
  ]);

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeAdsData());
    setTimeout(() => {
      message && dispatch(fetchGetMyInspection(token));
    }, 100);
  }, [message]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    errMsg && dispatch(removeAdsData());
    setTimeout(() => {
      errMsg && dispatch(fetchGetMyInspection(token));
    }, 100);
  }, [errMsg]);

  useEffect(() => {
    dispatch(fetchGetMyInspection(token));
    return () => {
      dispatch(removeMyAdsData());
    };
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-full h-full mt-2 relative">
      <div className="w-full flex items-center gap-x-3">
        <h6 className="text-2xl font-semibold">My Inspection</h6>
        <div className="w-fit py-1 px-3 flex items-center gap-x-3 border rounded-full bg-white">
          <FaMoneyBills className="text-xl text-primary" />
          <p className="font-semibold">{profile.classi_credit} Coins</p>
        </div>
      </div>

      {/* header */}
      <div className="w-full overflow-x-auto flex items-center mt-10 gap-x-5">
        <div
          onClick={handleClickAll}
          className={`${
            toggle.all
              ? "border-primary text-primary bg-purple-50"
              : "text-textPrimary"
          } border lg:p-5 p-10 w-24 h-24 flex flex-col justify-center cursor-pointer transition duration-1000 ease-in-out hover:bg-purple-50 items-center rounded-full`}
        >
          <p className="font-medium text-sm">All</p>
          <p className="font-semibold text-base mt-1">{all}</p>
        </div>

        <div
          onClick={handleClickToday}
          className={`${
            toggle.today
              ? "border-primary text-primary bg-purple-50"
              : "text-textPrimary"
          } border lg:p-5 p-10 w-24 h-24 flex flex-col justify-center cursor-pointer transition duration-1000 ease-in-out hover:bg-purple-50 items-center rounded-full`}
        >
          <p className="font-medium text-sm">Today</p>
          <p className="font-semibold text-base mt-1">{today}</p>
        </div>

        <div
          onClick={handleClickTomorrow}
          className={`${
            toggle.tomorrow
              ? "border-primary text-primary bg-purple-50"
              : "text-textPrimary"
          } border lg:p-5 p-10 w-24 h-24 flex flex-col justify-center cursor-pointer transition duration-1000 ease-in-out hover:bg-purple-50 items-center rounded-full`}
        >
          <p className="font-medium text-sm">Tomorrow</p>
          <p className="font-semibold text-base mt-1">{tomm}</p>
        </div>

        <div
          onClick={handleClickWeek}
          className={`${
            toggle.week
              ? "border-primary text-primary bg-purple-50"
              : "text-textPrimary"
          } border lg:p-5 p-10 w-24 h-24 flex flex-col justify-center cursor-pointer transition duration-1000 ease-in-out hover:bg-purple-50 items-center rounded-full`}
        >
          <p className="font-medium text-sm">Week</p>
          <p className="font-semibold text-base mt-1">{week}</p>
        </div>

        <div
          onClick={handleClickMonth}
          className={`${
            toggle.month
              ? "border-primary text-primary bg-purple-50"
              : "text-textPrimary"
          } border lg:p-5 p-10 w-24 h-24 flex flex-col justify-center cursor-pointer transition duration-1000 ease-in-out hover:bg-purple-50 items-center rounded-full`}
        >
          <p className="font-medium text-sm">Month</p>
          <p className="font-semibold text-base mt-1">{month}</p>
        </div>
      </div>

      <div className="w-full h-full mt-5 ">
        <MyInspectionCard myInspection={inspections} />
      </div>
    </div>
  );
};

export default MyInspection;
