import Cookies from "js-cookie";
import React from "react";
import { Navigate, Outlet, matchPath, useLocation } from "react-router-dom";

const AuthRoute = ({ Component }) => {
  const { pathname } = useLocation();
  const pathroad = matchPath("/auth", pathname);

  const token = Cookies.get("token");

  if (token) {
    return <Navigate to={`/`} replace />;
  }

  return <div className="w-full">{pathroad ? <Component /> : <Outlet />}</div>;
};
export default AuthRoute;
