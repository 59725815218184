import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  bodyTypes: "",
  brands: "",
  model: [],
};

export const Cars = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_BODY_TYPES:
      return {
        ...state,
        isloading: false,
        success: true,
        bodyTypes: action.payload,
      };

    case ActionTypes.GET_BRANDS:
      return {
        ...state,
        isloading: false,
        success: true,
        brands: action.payload,
      };

    case ActionTypes.GET_BIKE_TYPE:
      return {
        ...state,
        isloading: false,
        success: true,
        bodyTypes: action.payload,
      };

    case ActionTypes.GET_MODEL_BY_MAKE:
      return {
        ...state,
        isloading: false,
        success: true,
        model: action.payload,
      };

    case ActionTypes.REMOVE_CAR_DATA:
      return {
        isloading: true,
        success: false,
        bodyTypes: "",
        brands: "",
        model: [],
      };

    default:
      return state;
  }
};
